import React from "react";
import { FaTiktok, FaInstagram, FaPinterest, FaXTwitter, FaDiscord } from 'react-icons/fa6';
import { FaCoffee } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import '../css/App.css';

function Footer() {
    const year = new Date().getFullYear();
    return (
        <footer>
            <div className="social-media">
                <a href="https://www.instagram.com/hanfuworld/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="social-icon" />
                </a>
                <a href="https://www.tiktok.com/@hanfuworld" target="_blank" rel="noopener noreferrer">
                    <FaTiktok className="social-icon" />
                </a>
                <a href="https://twitter.com/HanfuWorldAI" target="_blank" rel="noopener noreferrer">
                    <FaXTwitter className="social-icon" />
                </a>
                <a href="https://discord.gg/tzf6u6rgvP" target="_blank" rel="noopener noreferrer">
                    <FaDiscord className="social-icon" />
                </a>
                <a href="https://www.pinterest.com/hanfu_world" target="_blank" rel="noopener noreferrer">
                    <FaPinterest className="social-icon" />
                </a>
                <Link to="/contact">
                    <MdEmail className="social-icon" />
                </Link>
            </div>
            {/* <button className="btn btn-light btn-coffee">
                <a href="https://www.buymeacoffee.com/hanfuworld" className="bmc-link" target="_blank" rel="noopener noreferrer" >
                   ❤️ 请我们喝杯咖啡 Buy us a coffee :) ☕️ ❤️
                </a>           
            </button> */}
            <p>{year} @ 汉服世界 Hanfu World</p>
        </footer>
    )
}

export default Footer; 