import React, { useState, useEffect } from 'react';
import NewTitlesList from './NewTitlesList';

const NewTitles = () => {
    const [newTitles, setNewTitles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
    const TITLES_NEW_API_URL = `${API_BASE_URL}/api/watch/new`;

    const typeMapping = {
        'cdrama': { en: 'C-Drama', cn: '连续剧' },
        'cmovie': { en: 'C-Movie', cn: '电影' },
        'donghua': { en: 'Donghua', cn: '动画' },
        'manhua': { en: 'Manhua', cn: '漫画' }
    };

    useEffect(() => {
        const fetchNewTitles = async () => {
            try {
                const response = await fetch(TITLES_NEW_API_URL);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const groupedTitles = groupTitlesByType(data);
                setNewTitles(groupedTitles);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch new titles');
                setLoading(false);
            }
        };

        fetchNewTitles();
    }, [TITLES_NEW_API_URL]);

    const groupTitlesByType = (titles) => {
        return titles.reduce((acc, title) => {
            const type = title.title_type_en.toLowerCase();
            if (!acc[type]) {
                acc[type] = {
                    titles: [],
                    type_en: typeMapping[type]?.en || title.title_type_en,
                    type_cn: typeMapping[type]?.cn || title.title_type_cn
                };
            }
            acc[type].titles.push(title);
            return acc;
        }, {});
    };

    if (loading) return <div>加载新加片名 Loading new titles...🎬</div>;
    if (error) return <div>{error}</div>;


    // console.log("New titles:", newTitles);

    return (
        <div className="page-wrapper">
            <h4>新加片名 New Titles Added</h4>
            <div className="new-titles-container">
                {Object.entries(newTitles).map(([type, groupData]) => (
                    <NewTitlesList
                        key={type}
                        title={`${groupData.type_cn} ${groupData.type_en}`}
                        titles={groupData.titles}
                        titlesType={type}
                    />
                ))}
            </div>
        </div>
    );
};

export default NewTitles; 