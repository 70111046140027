import React from 'react';

const UserTitleReviews = () => {
    return (
        <div className="page-wrapper">
            <div className="reviews-container">
                <h5>即将推出 🥰</h5>
                <h6>Coming soon 🥰</h6>
            </div>
        </div>
    )
}
export default UserTitleReviews;