// src/components/pay/Checkout.js

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStripe, useElements, CardElement, Elements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import './Checkout.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
const PAYMENT_API_URL = `${API_BASE_URL}/api/create-checkout-session`;

const Checkout = () => {
    const location = useLocation();
    const plan = location.state ? location.state.plan : null;
    const isMonthly = plan ? plan.isMonthly : true; // Default to true if not provided
    console.log("Received plan:", plan);

    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: name,
                email: email,
                address: {
                    line1: address,
                },
            },
        });

        if (error) {
            console.error('Error creating payment method:', error);
            return;
        }

        // Create a checkout session
        try {
            const response = await fetch(PAYMENT_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    planId: plan.id,
                    paymentMethodId: paymentMethod.id,
                }),
            });

            const session = await response.json();

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error(result.error.message);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <div className="checkout-container">
            {plan ? (
                <div className="checkout-content">
                    <div className="plan-details">
                        <h1>{plan.title_en}</h1>
                        <p>Subscribe to {plan.title_en}</p>
                        <p className="price">${isMonthly ? plan.priceMonthly : plan.priceYearly} per {isMonthly ? 'month' : 'year'}</p>
                        <p>Billed {isMonthly ? 'monthly' : 'yearly'}</p>
                        <hr />
                        <p>Subtotal: ${isMonthly ? plan.priceMonthly : plan.priceYearly}</p>
                        <p>Tax: Calculated at checkout</p>
                        <p>Total due today: ${isMonthly ? plan.priceMonthly : plan.priceYearly}</p>

                    </div>
                    <div className="payment-form">
                        <form onSubmit={handleSubmit}>
                            <h2>Contact information</h2>
                            <label>
                                Email
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </label>
                            <h2>Payment method</h2>
                            <CardElement className="card-element" />
                            <label>
                                Cardholder name
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </label>
                            <label>
                                Billing address
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </label>
                            <button type="submit" disabled={!stripe}>Pay</button>
                        </form>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default Checkout;
