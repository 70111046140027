// src/components/shop/SearchProductResults.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../../css/Search.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchProductResults() {

    const query = useQuery();
    const searchQuery = query.get('q');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const PRODUCTSEARCH_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/shop/search/products?q=${encodeURIComponent(searchQuery)}` || `http://localhost:3000/api/shop/search/products?q=${encodeURIComponent(searchQuery)}`

    useEffect(() => {
        if (searchQuery) {
            fetch(PRODUCTSEARCH_API_URL)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    // setResults(data); 
                    // Ensure data is always treated as an array, if not, convert it to an array with one element
                    setResults(Array.isArray(data) ? data : [data])
                    setLoading(false);
                })
                .catch(err => {
                    setError(err.toString());
                    setLoading(false);
                });
        }
    }, [searchQuery]);

    if (loading) return <div>加载中 Loading...</div>
    if (error) return <div>加载结果出错 Erorr loading results. 🙁</div>

    return (
        <div className="page-wrapper search-results">
            <h5 className="page-header">Search Results for "<span className="search-term">{searchQuery}</span>"</h5>
            <div className="results-container">
                {results.map((item, index) => (
                    <div key={index}>
                        <a href={item.product_affiliate_url} className="title-item-link result-item" target="_blank">
                            <div className="result-item-left">
                                <img src={item.product_image_url} alt={item.product_title_en} className="result-title-image" />
                            </div>
                            <div className="result-item-right">
                                <div className="result-item-details">
                                    <h6 className="result-item-title">{item.product_title_cn}</h6>
                                </div>
                                <div className="result-item-details">

                                    <span className="result-item-title">{item.product_title_en}</span>
                                </div>
                                <div className="result-item-details">
                                    {/* <span className="product-detail">{item.product_description_cn}</span>
                                    <span className="product-detail">{item.product_description_en}</span> */}
                                    <span className="product-detail">朝代 Dynasty: {item.product_dynasty_cn} {item.product_dynasty_en}</span>
                                    {/* <span className="product-detail">朝代: {product.product_dynasty_cn} Dynasty: {product.product_dynasty_en}</span> */}
                                    <span className="product-detail">${item.product_price_usd}</span>
                                    {/* <span className="product-detail">卖家 Seller: {product.product_vendor}</span> */}
                                    {/* <a href={item.product_affiliate_url} className="product-link" target="_blank" rel="noopener noreferrer">买TA！Buy Now! 😍</a> */}
                                </div>
                            </div>

                        </a>
                    </div>
                ))}
            </div>

        </div>
    );

}

export default SearchProductResults; 