// src/components/watch/NewTitlesList.js 

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import TitleCard from './TitleCard';
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import '../../css/Page.css';
import '../../css/TitleGrid.css';

const NewTitlesList = ({ title, titles, titlesType }) => {
    const scrollContainerRef = useRef(null);

    const scroll = (scrollOffset) => {
        scrollContainerRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    };

    return (
        <div className="new-titles-list">
            <h3 className="list-header">
                <Link to={`/watch/${titlesType}s`} className="list-header-link">
                    {title}
                    <span className="header-text">更多 More</span>
                </Link>
            </h3>
            <div className="titles-list-container">
                <span className="scroll-button" onClick={() => scroll(-800)}><MdArrowBackIos className="scroll-icon " /></span>
                <div className="titles-list" ref={scrollContainerRef}>
                    {titles.map((title, index) => (
                        <TitleCard key={index} title={title} />
                    ))}
                </div>
                <span className="scroll-button" onClick={() => scroll(800)}><MdArrowForwardIos className="scroll-icon" /></span>
            </div>
        </div>
    );
};

export default NewTitlesList; 