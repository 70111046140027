// src/components/Watchlinks.js

import React, { useState, useEffect } from 'react';
import '../../css/Page.css';

const Watchlinks = ({ titleId }) => {
    const [episodes, setEpisodes] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const BACKEND_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
    const WATCHLINKS_API_URL = BACKEND_BASE_URL + `/api/watch/titles/${titleId}/watchlinks`; 
    // console.log(WATCHLINKS_API_URL);

    // console.log(WATCHLINKS_API_URL);

    useEffect(() => {
        fetch(WATCHLINKS_API_URL)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok:' + response.statusText)
                }
                return response.json();
            })
            .then(data => {
                // console.log("Received data:", data)
                setEpisodes(data);
            })

            .catch(error => console.error('Error fetching watchlinks', error));
    }, [titleId, WATCHLINKS_API_URL]);

    const displayedEpisodes = showAll ? episodes : episodes.slice(0, 20);

    let watchlinkId; 

    return (
        <div className="watch-section">
            {episodes.length > 0 ?
                <div className="watchlink-container">
                    <h6 className="container-header">播放列表 Playlist</h6>
                    {displayedEpisodes.map((episode) => (
                        watchlinkId = episode.watchlink_id,
                        console.log(watchlinkId),
                        <a href={`/watch/titles/${titleId}/watchlinks/${watchlinkId}`} key={watchlinkId} target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-light btn-episodes">
                                <span>第{episode.watchlink_episode}集 Ep {episode.watchlink_episode}</span>
                            </button>
                        </a>
                    ))}
                    {episodes.length > 20 && (
                        <button className="btn btn-light btn-episodes show-all-button" onClick={() => setShowAll(!showAll)}>
                            {showAll ? '收回 Hide' : '展开全部 Show All'}
                        </button>
                    )}
                </div>
                :
                // <div>
                //     {episodes.map((episode) => (
                //         <button className="btn btn-light btn-episodes" key={episode.watchlink_id} onClick={() => window.open(episode.watchlink_url, "_blank")} >
                //             <span>{episode.watchlink_quality_cn}{episode.watchlink_quality_en}</span>
                //         </button>
                //     ))}
                // </div>
                <div className="container-text">Coming soon...</div>
            }


        </div>
    );
};

export default Watchlinks;