import React, { useState } from 'react';
import Sidebar from './Sidebar';
import PostForm from './PostForm';
import PostsList from './PostsList';
import '../../css/Page.css';
import '../../css/Forum.css';

const Forum = () => {
    const [showPostForm, setShowPostForm] = useState(false);

    const togglePostForm = () => {
        setShowPostForm(prevShowPostForm => !prevShowPostForm);
    };

    return (
        <div className="page-wrapper">
            <h5>论坛 🎙️</h5>
            <h5>Forum 🎙️</h5>
            <div className="form-header">
            </div>
            <div className="forum-main">
                {/* <Sidebar /> */}
                <div className="forum-container">
                    <div className="tabs">
                        <button className="tab btn btn-light btn-forum btn-create-post" onClick={togglePostForm}>
                            {showPostForm ? "Cancel" : "✍🏻 Create Post"}
                        </button>
                        <button className="tab active btn-light btn-forum">🕓 最新 Recent</button>
                        <button className="tab btn-light btn-forum">🔥 最火 Popular</button>
                        <button className="tab btn-light btn-forum">🎨 类别 Categories</button>
                    </div>

                    {showPostForm && <PostForm toggleForm={togglePostForm} />}
                    <PostsList />
                </div>
                {/* <div>Coming soon...❤️</div> */}
            </div>

        </div>
    )
};

export default Forum; 