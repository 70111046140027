// src/pages/CmoviesPage.js; 

import React from 'react';
import TitlesPage from './TitlesPage';

const CmoviesPage = () => {
    return (
        <TitlesPage type='cmovie' typeName="电影 CMovie" />
    )
}

export default CmoviesPage;