// src/components/create/SocialShare.js 

import React, { useState, useEffect } from 'react';
import {
    FacebookShareButton, TwitterShareButton, WhatsappShareButton,
    EmailShareButton, PinterestShareButton, RedditShareButton, WeiboShareButton
} from 'react-share';
import {
    FaFacebook, FaTwitter, FaWhatsapp, FaPinterestP, FaEnvelope, FaReddit, FaLink, FaCheck, FaWeibo, FaInstagram,
} from 'react-icons/fa';
import '../../css/Generate.css'; 

import { Spinner } from 'react-bootstrap';

function SocialShare({ imgUrl }) {

    const shareMessage = "Check out the Hanfu AI image I created! :) Try it for yourself at www.hanfuworld.ai/create! ❤️";
    const weiboShareUrl = `http://service.weibo.com/share/share.php?url=${encodeURIComponent(imgUrl)}&title=${encodeURIComponent(shareMessage)}&appkey=&pic=${encodeURIComponent(imgUrl)}`;
    const subject = "Check out the Hanfu AI Image I generated!"
    const body = `${shareMessage} ${imgUrl}`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;


    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(imgUrl).then(() => {
            setIsCopied(true); // Set isCopied to true when the text is copied
        });
    };

    // Reset the copied state after a delay
    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, 3000); // Reset after 3000ms (3 seconds)
            return () => clearTimeout(timer);
        }
    }, [isCopied]);


    return (
        <div className="social-share-buttons" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <div className="social-share-copy" onClick={handleCopy}>
                {isCopied ?
                    <FaCheck className="social-share-icon icon-link" />
                    :
                    <FaLink className="social-share-icon icon-link" />
                }
                {isCopied && <span className="share-text">Link copied!</span>}
            </div>
            <TwitterShareButton url={imgUrl} title={shareMessage}><FaTwitter className="social-share-icon icon-twitter" /></TwitterShareButton>
            <RedditShareButton url={imgUrl} title={shareMessage}><FaReddit className="social-share-icon icon-reddit" /></RedditShareButton>
            <a href="www.instagram.com" target="_blank" rel="noopener noreferrer" onClick={() => alert("下载图片然后分享到Insta! Save this image and share it on Instagram! 😘")}>
                <FaInstagram className="social-share-icon icon-instagram" />
            </a>
            <PinterestShareButton url={imgUrl} media={imgUrl} description={shareMessage}><FaPinterestP className="social-share-icon icon-pinterest" /></PinterestShareButton>
            <FacebookShareButton url={imgUrl} quote={shareMessage} hashtag="Hanfu"><FaFacebook className="social-share-icon icon-facebook" /></FacebookShareButton>
            <a href={weiboShareUrl} target="_blank" rel="noopener noreferrer">
                <WeiboShareButton className="social-share-icon icon-weibo" />
            </a>
            <WeiboShareButton url={imgUrl} title={shareMessage}><FaWeibo className="social-share-icon icon-weibo" /></WeiboShareButton>
            <WhatsappShareButton url={imgUrl} title={shareMessage}><FaWhatsapp className="social-share-icon icon-whatsapp" /></WhatsappShareButton>
            {/* <EmailShareButton url={imgUrl} subject="Hanfu AI Image" body={shareMessage} separator=" " ><FaEnvelope className="social-share-icon icon-email" /></EmailShareButton> */}
            <a target="_top" href={mailtoLink} url={imgUrl} subject="Hanfu AI Image" body={shareMessage} separator=" " ><FaEnvelope className="social-share-icon icon-email" /></a>
        </div>
    );
}

export default SocialShare; 