// src/components/TitleFilter.js
import React, { useState, useEffect } from 'react';
import '../../css/Page.css';

const TitleFilter = ({ onFilterChange, onSelectionChange, filters }) => {

    const [genres, setGenres] = useState([]);
    const [dynasties, setDynasties] = useState([]);
    const [years, setYears] = useState([]);

    useEffect(() => {

        const GENRES_API_URL = process.env.REACT_APP_API_BASE_URL + "/api/watch/genres" || 'http://localhost:3000/api/watch/genres';
        const DYNASTIES_API_URL = process.env.REACT_APP_API_BASE_URL + "/api/watch/dynasties" || 'http://localhost:3000/api/watch/dynasties';

        // Fetch genres 
        const fetchGenres = async () => {
            const response = await fetch(GENRES_API_URL);
            const data = await response.json();
            // console.log("console logging,", data);
            setGenres([{ genre_id: 'all', genre_name_cn: '全部', genre_name_en: 'All' }, ...data]);
        };

        // Fetch dynasties 
        const fetchDynasties = async () => {
            const response = await fetch(DYNASTIES_API_URL);
            const data = await response.json();
            setDynasties([{ dynasty_id: 'all', dynasty_name_cn: '全部', dynasty_name_en: 'All' }, ...data]);
        };

        const generatedYears = () => {
            const currentYear = new Date().getFullYear();
            const yearsArray = Array.from({ length: currentYear - 1999 }, (_, i) => `${currentYear - i}`);
            const decades = ['1990s', '1980s', '1970s', '1960s', '1950s', '1940s', '1930s', '1920s'];
            setYears(['全部 All', ...yearsArray, ...decades]);
        };

        fetchGenres();
        fetchDynasties();
        generatedYears();

    }, []);

    return (
        <div className="filter-container">
            {/* <div className="filter-section">
                <h6 className="filter-header filter-genre">
                    <span>类别</span>
                    <span>Genres</span>
                </h6>
                <div className="filter-selection">
                    {genres.map((genre) => (
                        <button
                            disabled
                            // className={`btn btn-light btn-filter ${filters.genre === genre.genre_name_en ? 'btn-filter-active' : ''}`}
                            className={`btn btn-light btn-filter btn-genre ${filters.genre === (genre.genre_name_en === 'All' ? 'all' : genre.genre_name_en) ? 'btn-filter-active' : ''}`}
                            // key={genre.genre_id} onClick={() => onFilterChange('genre', genre.genre_name_en)}>
                            key={genre.genre_id} 
                            onClick={() => {
                            onFilterChange('genre', genre.genre_name_en === 'All' ? 'all' : genre.genre_name_en);
                            onSelectionChange('genre', {cn: genre.genre_name_cn, en: genre.genre_name_en});
                            }}
                            >
                            {genre.genre_name_cn} {genre.genre_name_en}
                        </button>
                    ))}
                </div>
            </div> */}
            {/* <div className="filter-section">
                <h6 className="filter-header filter-dynasty">
                    <span>朝代</span>
                    <span>Dynasties</span>
                </h6>
                <div className="filter-selection">
                    {dynasties.map((dynasty) => (
                        <button
                            disabled
                            // className={`btn btn-light btn-filter ${filters.dynasty === dynasty.dynasty_name_en ? 'btn-filter-active' : ''}`}
                            className={`btn btn-light btn-filter btn-dynasty ${filters.dynasty === (dynasty.dynasty_name_en === 'All' ? 'all' : dynasty.dynasty_name_en) ? 'btn-filter-active' : ''}`}
                            // key={dynasty.dynasty_id} onClick={() => onFilterChange('dynasty', dynasty.dynasty_name_en)}>
                            key={dynasty.dynasty_id} 
                            onClick={() => {
                            onFilterChange('dynasty', dynasty.dynasty_name_en === 'All' ? 'all' : dynasty.dynasty_name_en);
                            onSelectionChange('dynasty', {cn: dynasty.dynasty_name_cn, en: dynasty.dynasty_name_en});
                            }}>
                            {dynasty.dynasty_name_cn} {dynasty.dynasty_name_en}
                        </button>
                    ))}
                </div>

            </div> */}
            <div className="filter-section">
                <h6 className="filter-header">
                    <span>年份</span>
                    <span>Years</span>
                </h6>
                <div className="filter-selection">
                    {years.map((year, index) => (
                        <button
                            className={`btn btn-light btn-filter ${filters.year === (year === '全部 All' ? 'all' : year) ? 'btn-filter-active' : ''}`}
                            // key={index} onClick={() => onFilterChange('year', year)}>
                            key={index} onClick={() => onFilterChange('year', year === '全部 All' ? 'all' : year)}>
                            {year}
                        </button>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default TitleFilter; 