// src/components/TitleCard.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/Product.css';

const ProductCard = ({ product }) => {
    
    return (
        <div className="product-card">
            <a href={product.product_affiliate_url} className="product-item-link" target="_blank">
                <img className="product-image" src={product.product_image_url} alt={product.product_name_en} />
                <div className="product-name-cn">{product.product_title_cn}</div>
                <div className="product-name-en">{product.product_title_en}</div>
                <div className="product-price-usd">${product.product_price_usd}</div>
            </a>
        </div>
    )
}

export default ProductCard; 