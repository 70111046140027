// src/pages/TitlePage.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { FaHeart, FaRegHeart, FaBookmark, FaRegBookmark } from 'react-icons/fa'; // Ensure you have these icons
import ProductReviewForm from './ProductReviewForm';
import ProductReviews from './ProductReviews';
import '../../css/Page.css';
import '../../css/Shop.css';

function ProductPage() {
    const { productId } = useParams();
    const { user } = useContext(UserContext);
    const [productDetails, setProductDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    // default active tab
    const [activeTab, setActiveTab] = useState('');

    // Define the API endpoint. Adjust it according to your backend setup.
    const PRODUCT_API_URL = process.env.REACT_APP_API_BASE_URL + `/api/shop/products/${productId}` || `http://localhost:3000/api/shop/products/${productId}`;

    const INCREMENT_API_URL = PRODUCT_API_URL + "/increment-view" || `http://localhost:3000/api/shop/products/${productId}/increment-view`;

    // const FAVORITELIST_API_URL = user && user.favoritelistId
    //     ?
    //     process.env.REACT_APP_BACKEND_BASE_URL + `/api/favoritelists/${user.favoritelistId}/products` || `http://localhost:3000/api/favoritelists/${user.favoritelistId}/products` // Assuming user.favoritelistId is available
    //     :
    //     null;

    const handleFavoriteToggle = () => {
        console.log('You favorited a product!')
    }

    const handleAddToCart = () => {
        console.log('You added this product to your shopping cart!')
    }

    useEffect(() => {

        // Fetching the details of the title 
        fetch(PRODUCT_API_URL)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setProductDetails(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the product details:', error);
                setError(error);
                setLoading(false);
            });
        // Dependency array with titleId to re-run useEffect when titleId changes
    }, [productId]);

    useEffect(() => {
        fetch(INCREMENT_API_URL, {
            method: 'POST'
        })
            .catch(error => console.error('Error incrementing view count:', error));
    }, [productId]);

    // const handleFavoriteToggle = () => {
    //     if (!user || !user.favoritelistId) {
    //         console.error("You have not created a Favoritelist.");
    //         return;
    //     }

    //     // Check if the FAVORITELIST_API_URL is ready
    //     if (!FAVORITELIST_API_URL) {
    //         console.error("Favoritelist API URL not set.");
    //         return;
    //     }

    //     // API call to add/remove from favorite
    //     fetch(FAVORITELIST_API_URL, {
    //         // method: 'POST',
    //         method: isFavorite ? 'DELETE' : 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ${user.token}' // Ensure authentication 
    //         },
    //         // body: JSON.stringify({ userId: user.userId, titleId: titleId })
    //         body: JSON.stringify({ productId: productId })
    //     })
    //         .then(response => response.json())
    //         .then(() => setIsFavorite(!isFavorite))
    //         .catch(error => console.error('Error handling favorite:', error));
    // };

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message} </div>
    }

    const handleLikeToggle = () => {
        console.log("you liked a product!")
    }


    const handleSaveToggle = () => {
        console.log("you saved a product!")
    }


    return (
        <div className="page-wrapper">
            <div className="product-page-left">
                <div className="product-info">
                    <div className="product-top">
                        <div className="product-top-container">
                            <div className="product-image-container">
                                <img
                                    className="product-image"
                                    src={productDetails.product_image_url}
                                    alt={productDetails.product_title_en}
                                />
                            </div>
                            <div className="product-details">
                                <div className="product-details-item">
                                    <span className="product-item-title">{productDetails.product_title_cn}</span>
                                </div>
                                <div className="product-details-item">
                                    <span className="product-item-title">{productDetails.product_title_en}</span>
                                </div>
                                {/* <div className="product-details-item">
                                    <span className="product-item-label">SKU {productDetails.product_sku}:</span>{" "}
                                </div> */}
                                <div className="product-details-item">
                                    <span className="product-item-label">评分 Rating:</span>{" "}
                                    <div className="product-rating-text">
                                        <span className="product-item-text">{productDetails.product_average_rating || "Coming soon..."}
                                        </span>
                                        <span className="product-rating-note">{productDetails.product_reivews_count == 0 ? <span>(0)</span> : <span>({productDetails.product_reivews_count})</span>}</span>
                                    </div>
                                </div>
                                <div className="product-details-item">
                                    <span className="title-rating-note">{productDetails.product_average_rating == 0 ?
                                        <span>(Be the first reviewer! ✍🏻)</span>
                                        :
                                        <span>""</span>
                                    }</span>
                                </div>
                                <div className="product-details-item">
                                    <span className="product-item-label">价格 Price:</span>{" "}
                                    <span className="product-item-text">{productDetails.product_price_usd}</span>
                                    {/* <span className="product-item-text">{productDetails.product_price_current}</span>
                                    <span className="product-item-text">{productDetails.product_price_original}</span> */}
                                </div>
                                <div className="product-details-item">
                                    <span className="product-item-label">颜色 Color:</span>{" "}
                                    {/* render a list of colors in filled circles */}
                                    <span className="title-item-text"></span>
                                </div>
                                <div className="product-details-item">
                                    <span className="product-item-label">尺码 Size:</span>{" "}
                                    {/* render a list of buttons in sizes, grey out the ones not available */}
                                    <span className="title-item-text"></span>
                                </div>

                                <div className="product-details-item">
                                    <span className="product-item-label">简介 Description:</span>{" "}
                                    <span className="title-item-text"> {productDetails.product_description_cn}</span>
                                    <span className="title-item-text"> {productDetails.product_description_en}</span>
                                </div>
                                <div className="product-details-item">
                                    {/* <button className="btn btn-light btn-title-action" onClick={handleAddToCart}>
                                        加入购物车 ADD TO CART
                                    </button> */}
                                    <button className="btn btn-light btn-shop-action">
                                        <a href={productDetails.product_affiliate_url} className="product-link" target="_blank">
                                            购买 BUY!
                                        </a>
                                    </button>
                                </div>
                                {/* <div className="product-actions">
                                    <button className="btn btn-light btn-title-action" onClick={handleLikeToggle}>
                                        <span>
                                            喜欢
                                            {isLiked ?
                                                <FaHeart className="title-action-icon" />
                                                :
                                                <FaRegHeart className="title-action-icon" />
                                            }
                                        </span>
                                        <span>
                                            Like
                                        </span>
                                    </button>
                                    <button className="btn btn-light btn-title-action" onClick={handleSaveToggle}>
                                        <span>
                                            收藏
                                            {isSaved ?
                                                <FaBookmark className="title-action-icon" />
                                                :
                                                <FaRegBookmark className="title-action-icon" />
                                            }
                                        </span>
                                        <span>
                                            Save
                                        </span>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className="title-top-right">
                        </div>
                        <div className="title-mid-container">
                            {/* <div className="tabbed-container">
                                <div className="title-actions">
                                    <button
                                        className={`btn btn-light btn-title-action tab-button ${activeTab === 'reviews' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('reviews')}
                                    >
                                        所有评价 All Reviews
                                    </button>
                                    <button
                                        className={`btn btn-light btn-title-action tab-button ${activeTab === 'images' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('images')}
                                    >
                                        图片评价 Photo Reviews
                                    </button>
                                </div>
                                <div className="tab-content">
                                    {activeTab === 'reviews' && (
                                        <div className="product-reviews">
                                        </div>
                                    )}
                                    {activeTab === 'images' && (
                                        <div className="product-reviews">
                                        </div>
                                    )}
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {/* <div className="product-bottom-container">
                        <ProductReviewForm productId={productDetails.product_id} />
                        <ProductReviews productId={productId} />
                        <ProductComments productId={productId} />
                    </div> */}
                </div>
            </div>
            <div className="product-page-right">
            </div>
        </div>
    )
}

export default ProductPage;





