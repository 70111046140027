import React from 'react';
import PricingChart from './PricingChart';
import PricingStripe from './PricingStripe';

const Pricing = () => {
    return (
        <div className="page-wrapper">
            <h5>订阅</h5>
            <h5>Pricing ✅</h5>
            <PricingChart />
            {/* <PricingStripe /> */}
        </div>
    )
}

export default Pricing; 