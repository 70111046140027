// src/components/create/AdminGallery.js

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 
import UserContext from '../../context/UserContext'; 
import '../../css/Generate.css';

const AdminGallery = () => {
    const [images, setImages] = useState([]);
    const { isTokenValid, getToken, logoutUser } = useContext(UserContext); 
    const navigate = useNavigate(); 

    const ADMIN_IMAGES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/admin/create/gallery` || 'http://localhost:3000/api/create/gallery';

    useEffect(() => {
        const fetchImages = async () => {
            if (!isTokenValid()) {
                console.error('Token is invalid or expired'); 
                logoutUser(); 
                // Redirect to login page
                navigate('/login'); 
                return; 
            }
            const token = getToken();

            try {
                const response = await fetch(ADMIN_IMAGES_API_URL, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setImages(data.images);
                } else if (response.status === 401) {
                    console.error('Unauthorized access'); 
                    logoutUser(); 
                    // Redirect to login page
                    navigate('/login'); 
                } else {
                    console.error('Failed to fetch images', await response.text());
                }
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [ADMIN_IMAGES_API_URL, isTokenValid, getToken, logoutUser]); 

    function handleImageClick(event) {
        event.preventDefault();
        console.log("Image was clicked but did not navigate.")
    }

    const handleContextMenu = (event) => {
        event.preventDefault(); 
        console.log("Right-click disabled on this image.")
    }

    return (
        <div className="page-wrapper">
            <div className="gallery-container">
                <h2 className="create-header">社区图片画廊</h2>
                <h3 className="create-subheader">Community Image Gallery 🖼️</h3>
                <div className="gallery-grid">
                    {images.map((image) => (
                        <div key={image.ai_image_id} className="gallery-item">
                            <img className="gallery-image"
                                src={image.generated_image_url}
                                onClick={handleImageClick}
                                onContextMenu={handleContextMenu}
                                alt="generated" />
                            <div className="gallery-image-info">
                                <p>{image.generated_image_url.split('.com/')[1]}</p>
                                <p>{new Date(image.created_at).toLocaleDateString()}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AdminGallery; 