// src/components/TitleCard.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay, FaShoppingCart } from 'react-icons/fa'; // Import the play icon from react-icons
import '../../css/TitleGrid.css';
import '../../css/TitleCard.css';

const TitleCard = ({ title }) => {
    return (
        <div className="title-card">
            <Link to={`/watch/titles/${title.title_id}`} className="title-item-link">
                <div className="title-image-container">
                    <img className="title-image" src={title.title_image_url} alt={title.title_name_en} />
                    {title.title_video_url && (
                        <div className="title-hover-overlay">
                            <FaPlay className="title-play-icon" />
                        </div>
                    )}
                </div>
                {title.title_average_rating && (
                    <div className="title-rating">
                        {Number(title.title_average_rating).toFixed(1)}
                    </div>
                )}
                {/* <div className="title-shop">
                    <FaShoppingCart className="title-shop-icon" />
                </div> */}
                <div className="title-card-title title-name-cn">{title.title_name_cn}</div>
                <div className="title-card-title title-name-en">{title.title_name_en}</div>
            </Link>
        </div>
    )
}

export default TitleCard; 