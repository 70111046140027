import React from 'react';
import confetti from 'canvas-confetti';

const PartyButton = ({ children }) => {
    const triggerConfetti = () => {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });
    };

    return (
        <button className="btn btn-light btn-buy-token" onClick={triggerConfetti}>
            {children}
        </button>
    )
}

export default PartyButton; 