// src/components/AppNavbar.js

import React, { useContext, useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// import { useAuth } from '../context/UserContext';
import { useLocation } from 'react-router-dom';
import UserContext from '../context/UserContext';
import SearchTitleBar from './watch/SearchTitleBar';
import SearchProductBar from './shop/SearchProductBar';
import { BsPersonCircle } from "react-icons/bs";
// import { IoMdArrowDropdown } from "react-icons/io";
import { MdArrowDropDown } from "react-icons/md";
import '../css/AppNavbar.css';

function AppNavbar() {

    const location = useLocation();
    // Correctly access the user and logoutUser function from UserContext
    const { user, logoutUser } = useContext(UserContext);
    // console.log(user);
    // console.log(user.userId);
    const navigate = useNavigate();
    // state to control the navbar expansion
    const [expanded, setExpanded] = useState(false);

    const renderSearchBar = () => {
        if (location.pathname.includes('/watch')) {
            return <SearchTitleBar />;
        } else if (location.pathname.includes('/shop')) {
            return <SearchProductBar />;
        }
        return null;
    };

    const renderBanner = () => {
        if (location.pathname.includes('/watch')) {
            return <div className="navbar-banner">
                <span className="banner-text">更多片名即将推出！More titles coming soon! 🎉</span>
            </div>
        } else if (location.pathname.includes('/shop')) {
            return <div className="navbar-banner">
                <span className="banner-text">更多产品即将推出！More products coming soon! 🎉</span>
            </div>
        }
    };

    // console.log("user:", user);
    // console.log("user profile photo:", user.profilePhoto);

    const renderProfileIcon = () => {
        if (user && user.profilePhoto) {
            return (
                <img
                    className="navbar-profile-photo"
                    src={user.profilePhoto}
                    alt="User Avatar"
                />
            );
        } else {
            return <BsPersonCircle className="navbar-profile-icon" />
        }
    };


    // Adjust the function to toggle the navbar state
    const handleToggle = () => setExpanded(prevExpanded => !prevExpanded);
    // Function to close the navbar when a link is clicked

    const closeNavbar = () => setExpanded(false);

    const handleLogout = () => {
        logoutUser();
        navigate('/');
        closeNavbar(); // Ensure navbar is closed after logging out
    };

    return (
        <Navbar expanded={expanded}
            // className="navbar" 
            className={`navbar ${expanded ? 'expanded' : ''}`}
            bg="light" expand="xl" >
            <Container className="container">
                <Navbar.Brand as={Link} to="/" className="navbar-left">
                    <Nav className="navbar-items">
                        <div className="navbar-brand">
                            <span className="navbar-item-text navbar-text-gradient-1">🌎 汉服世界</span>
                            <span className="navbar-item-text navbar-text-gradient-1">Hanfu World</span>
                        </div>
                    </Nav>
                </Navbar.Brand>
                <Navbar.Toggle onClick={handleToggle} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">

                    <Nav className="navbar-items navbar-right">
                        {user ? (
                            <>
                                {/* <Nav.Item>
                                    <Nav.Link as={Link} to="/contact" className="navbar-item" onClick={closeNavbar}>
                                        <span className="navbar-item-text">💌 联系</span>
                                        <span className="navbar-item-text">Contact</span>
                                    </Nav.Link>
                                </Nav.Item> */}
                                <NavDropdown
                                    className="nav-dropdown"
                                    title={renderProfileIcon()}
                                    id="profile-dropdown"
                                >
                                    {/* <NavDropdown.Item as={Link} to={`/users/${user?.username}`}>账号 Account ⚙️</NavDropdown.Item> */}
                                    <NavDropdown.Item as={Link} to={`/users/${user?.username || ''}/account`} onClick={closeNavbar}>账号 My Account 🏡</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user?.username || ''}`} onClick={closeNavbar}>资料 My Profile 🤖</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user?.username || ''}/images`} onClick={closeNavbar}>图片 My Images 🎨</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user?.username || ''}/likes`} onClick={closeNavbar}>最爱 My Likes ❤️</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user?.username || ''}/saves`} onClick={closeNavbar}>收藏 My Saves 🔖</NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to={`/users/${user.userId}/title_reviews`}>My Reviews</NavDropdown.Item> */}
                                    {/* <NavDropdown.Item as={Link} to={`/users/${user.userId}`}>账号 Account ⚙️</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user.userId}`} onClick={closeNavbar}>资料 Profile 🤖</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user.userId}/images`} onClick={closeNavbar}>图片 Images 🎨</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user.userId}/likes`} onClick={closeNavbar}>最爱 Likes ❤️</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user.userId}/saves`} onClick={closeNavbar}>收藏 Saves 🔖</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/users/${user.userId}/title_reviews`}>My Reviews</NavDropdown.Item> */}
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={handleLogout}>退出 Logout</NavDropdown.Item>
                                </NavDropdown>

                            </>
                        ) : (
                            <div className="auth-links">
                                {/* <Nav.Item>
                                    <Nav.Link as={Link} to="/contact" className="navbar-item" onClick={closeNavbar}>
                                        <span className="navbar-item-text">💌 联系</span>
                                        <span className="navbar-item-text">Contact</span>
                                    </Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item>
                                    <Nav.Link as={Link} to="/signup" className="navbar-item" onClick={closeNavbar}>
                                        <span className="navbar-item-text">🏠 注册</span>
                                        <span className="navbar-item-text">Signup</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link as={Link} to="/login" className="navbar-item" onClick={closeNavbar}>
                                        <span className="navbar-item-text">🔑 登陆</span>
                                        <span className="navbar-item-text">Login</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </div>
                        )}
                    </Nav>

                    <Nav className="navbar-items navbar-center">
                        {/* <Nav.Item>
                            <Nav.Link as={Link} to="/learn" className="navbar-item">
                                <span className="navbar-item-text">学习</span>
                                <span className="navbar-item-text">Learn</span>
                            </Nav.Link>
                        </Nav.Item> */}
                        <NavDropdown className="nav-dropdown" title={
                            <div className="dropdown-title">
                                <div className="dropdown-texts navbar-item">
                                    <span className="navbar-item-text">▶️ 观看</span>
                                    <span className="navbar-item-text">Watch</span>
                                </div>
                                {/* <span className="dropdown-icon"><MdArrowDropDown className="navbar-dropdown-icon" /></span> */}
                            </div>
                        } id="watch-dropdown">
                            <NavDropdown.Item as={Link} to="/watch/trendings" onClick={closeNavbar}>热门 Trending 🔥</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/watch/new" onClick={closeNavbar}>新加 Newly Added 🎉</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/watch/cdramas" onClick={closeNavbar}>连续剧 C-Drama 📺</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/watch/cmovies" onClick={closeNavbar}>电影 C-Movie 🎬 </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/watch/donghuas" onClick={closeNavbar}>动画 Donghua 😻</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/watch/manhuas" onClick={closeNavbar}>漫画 Manhua ✍🏻</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown className="nav-dropdown" title={
                            <div className="dropdown-title">
                                <div className="dropdown-texts navbar-item navbar-text-gradient-1">
                                    <span className="navbar-item-text">❤️ 创作</span>
                                    <span className="navbar-item-text">Create!</span>
                                </div>
                                {/* <span className="dropdown-icon"><MdArrowDropDown className="navbar-dropdown-icon" /></span> */}
                            </div>
                        } id="create-dropdown">
                            <NavDropdown.Item as={Link} to="/create" onClick={closeNavbar}>生成图像 Generate AI Image! 🎨</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/create/gallery" onClick={closeNavbar}>社区画廊 Community Gallery 🖼️</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown className="nav-dropdown" title={
                            <div className="dropdown-title">
                                <div className="dropdown-texts navbar-item">
                                    <span className="navbar-item-text">🛍️ 购物</span>
                                    <span className="navbar-item-text">Shop</span>
                                </div>
                                {/* <span className="dropdown-icon"><MdArrowDropDown className="navbar-dropdown-icon" /></span> */}
                            </div>
                        } id="shop-dropdown">
                            <NavDropdown.Item as={Link} to="/shop" onClick={closeNavbar}>产品 Products 👘</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown className="nav-dropdown" title={
                            <div className="dropdown-title">
                                <div className="dropdown-texts">
                                    <span className="navbar-item-text">💬 交流</span>
                                    <span className="navbar-item-text">Discuss</span>
                                </div>
                            </div>
                        } id="shop-dropdown">
                            <NavDropdown.Item as={Link} to="/forum" onClick={closeNavbar}>论坛 Forum 🎙️</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown className="nav-dropdown" title={
                            <div className="dropdown-title">
                                <div className="dropdown-texts">
                                    <span className="navbar-item-text">🫶🏻 体验</span>
                                    <span className="navbar-item-text">Experience</span>
                                </div>
                            </div>
                        } id="shop-dropdown">
                            <NavDropdown.Item as={Link} to="/events" onClick={closeNavbar}>活动 Events 🥳</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown className="nav-dropdown" title={
                            <div className="dropdown-title">
                                <div className="dropdown-texts navbar-item">
                                    <span className="navbar-item-text">➕ 更多</span>
                                    <span className="navbar-item-text">More</span>
                                </div>
                                {/* <span className="dropdown-icon"><MdArrowDropDown className="navbar-dropdown-icon" /></span> */}
                            </div>
                        } id="more-dropdown">
                            <NavDropdown.Item as={Link} to={`/qrcode`} onClick={closeNavbar}>二维码 QR Code 🧩</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/pricing`} onClick={closeNavbar}>订阅 Pricing ✅</NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to={`/about`}>关于 About</NavDropdown.Item> */}
                            <NavDropdown.Item as={Link} to={`/contact`} onClick={closeNavbar}>联系 Contact Us 💌</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>

                    {expanded && (
                        <div className="close-dropdown">
                            <button className="btn btn-light btn-close" onClick={closeNavbar}></button>
                            <button className="btn btn-light btn-close-dropdown" onClick={closeNavbar}>收起 Close</button>
                        </div>
                    )}

                </Navbar.Collapse>
            </Container>

            <Container className="container searchbar-container">
                {renderSearchBar()}
            </Container>
            {/* <div>
                {renderBanner()}
            </div> */}
        </Navbar>
    )
}

export default AppNavbar; 