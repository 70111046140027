// src/components/shop/SearchProductBar.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaTimes } from "react-icons/fa";
import SearchProductDropdown from './SearchProductDropdown';
import '../../css/AppNavbar.css';


function SearchProductBar() {
    const [query, setQuery] = useState('');
    const [dropdownResults, setDropdownResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    const handleSearch = (e) => {
        e.preventDefault();
        setShowDropdown(false);
        navigate(`/shop/search/products?q=${encodeURIComponent(query)}`);
    };

    const PRODUCTSEARCH_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/shop/search/products` || `http://localhost:3000/api/shop/search/products`

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (query.length > 0) {
            fetch(`${PRODUCTSEARCH_API_URL}?q=${encodeURIComponent(query)}`)
                .then(response => response.json())
                .then(data => {
                    // setDropdownResults(Array.isArray(data) ? data.slice(0, 5) : [data].slice(0, 5));
                    setDropdownResults(Array.isArray(data) ? data : [data]);
                    setShowDropdown(true);
                })
                .catch(error => console.error('Error fetching dropdown results:', error));
        } else {
            setDropdownResults([]);
            setShowDropdown(false);
        }
    }, [query]);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleClearInput = () => {
        setQuery('');
        setDropdownResults([]);
        setShowDropdown(false);
    };

    return (
        <div className="searchbar-container" ref={dropdownRef}>
            <form className="searchbar-form" onSubmit={handleSearch}>
                {query && (
                    <button type="button" className="btn-clear" onClick={handleClearInput}>
                        <FaTimes className="clear-icon" />
                    </button>
                )}
                <input
                    className="search-input"
                    type="text"
                    placeholder="搜索商品 Search for products..."
                    value={query}
                    onChange={handleInputChange}
                />
                <button className="btn-searchbar" type="submit"><FaSearch className="search-icon" /></button>
            </form>
            {showDropdown &&
                <SearchProductDropdown
                    results={dropdownResults}
                    onSelect={(item) => {
                        setQuery(item.product_title_en);
                        setShowDropdown(false);
                        navigate(`/shop/products/${item.product_id}`);
                    }} 
                />}
        </div>

    )
}

export default SearchProductBar; 