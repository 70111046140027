// src/components/shop/ProductsPage.js
import React, { useState, useEffect } from 'react';
import ProductFilter from './ProductFilter';
import ProductSort from './ProductSort';
import ProductGrid from './ProductGrid';
import '../../css/Page.css';

// type prop will be 'ruqun', 'mamianqun', etc.
const ProductsPage = () => {

    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({
        season: 'all',
        occasion: 'all',
        dynasty: 'all',
    });
    const [sortBy, setSortBy] = useState('added');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({
        season: { cn: '全部', en: 'All' },
        occasion: { cn: '全部', en: 'All' },
        dynasty: { cn: "全部", en: 'All' },
        // year: 'all',
        sortBy: { cn: '添加', en: 'Added' }
    });
    const [selectedSummary, setSelectedSummary] = useState('');

    useEffect(() => {
        console.log("Current sortBy:", sortBy);
        // Call this function to update the summary based on the current state
        updateSelectedSummary();

        const fetchProducts = async () => {
            setLoading(true);
            try {
                // Define the query parameters
                let queryParams = new URLSearchParams({
                    ...filters,
                    sortBy,
                    // Add the type to query parameters 
                    // type
                    // type: type || 'defaultType'
                }).toString();

                // Adjust the query parameters to exclude 'all' filters
                queryParams = queryParams.split('&').filter(q => !q.includes('all')).join('&');

                console.log("Query params:", queryParams);
                // Construct the API URL with query parameters
                // const PRODUCTS_API_URL = process.env.REACT_APP_API_BASE_URL + `/api/shop/products` || `http://localhost:3000/api/shop/products`;

                const PRODUCTS_QUERY_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/shop/products?${queryParams}` || `http://localhost:3000/api/shop/products?${queryParams}`;
                // console.log(PRODUCTS_QUERY_API_URL)
                console.log("Full API URL:", PRODUCTS_QUERY_API_URL);

                const response = await fetch(PRODUCTS_QUERY_API_URL);
                if (!response.ok) {
                    throw new Error('Oh no 😅, there is no result for your filtered search, but we are constantly adding new products, please check back! :)');
                }
                const data = await response.json();

                // Filter out sold-out products 
                const availableProducts = data.filter(product => product.product_status_en !== 'Soldout'); 
                
                // setProducts(data);
                setProducts(availableProducts); 
                setError(null); // Reset error state on successful fetch
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        // Only fetch products if type is defined

        // if (type) {
        //     fetchProducts();
        // } else {
        //     console.error("Product type is undefined"); 
        // }
        fetchProducts();
        // Include 'type' in the dependency array
        // }, [filters, sortBy, type]);    
    }, [filters, sortBy]);

    const handleFilterChange = (filterCategory, value) => {
        // setFilters(prev => ({ ...prev, [filterCategory]: value }));
        const backendValue = value === 'All' ? 'all' : value;
        setFilters(prev => ({ ...prev, [filterCategory]: backendValue }));
    };

    const handleSelectionChange = (category, { cn, en }) => {
        setSelectedOptions(prev => ({ ...prev, [category]: { cn, en } }));
    };

    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
    };
    // console.log(sortBy);

    // const updateSelectedSummary = () => {
    //     // Construct the summary string based on selected filters and sorting
    //     const genreText = filters.genre === 'all' ? 'All' : filters.genre; 
    //     const dynastyText = filters.dynasty === 'all' ? 'All': filters.dynasty; 
    //     const yearText = filters.year === 'all' ? 'All' : filters.year; 
    //     const sortByText = sortBy.charAt(0).toUpperCase() + sortBy.slice(1); // Capitalize the first letter  
    //     const summary = `Showing results for Genre: ${genreText} | Dynasty: ${dynastyText} | Year: ${yearText} | Sort by: ${sortByText}`; 
    //     setSelectedSummary(summary);
    // }

    /// Selected summary with Chinese name too: 
    const updateSelectedSummary = () => {
        const { season, occasion, dynasty } = selectedOptions;
        // const yearText = filters.year === 'all' ? '全部 All' : filters.year;
        const sortByTextEn = sortBy.charAt(0).toUpperCase() + sortBy.slice(1);
        // const sortByTextCn = getChineseNameForSortOption(sortBy); 
        // const summary = `展示结果 Showing results：类别 Item Category: ${itemcategory.cn} ${itemcategory.en} | 朝代 Dynasty: ${dynasty.cn} ${dynasty.en} | 年份 Year: ${yearText}`;
        // const summary = `展示结果 Showing results：种类 Type: ${typeName} | 季节 Season: ${season.cn} ${season.en} | 场合 Occasion: ${occasion.cn} ${occasion.en} | 朝代 Dynasty: ${dynasty.cn} ${dynasty.en}`;
        const summary = `展示结果 Showing results：季节 Season: ${season.cn} ${season.en} | 场合 Occasion: ${occasion.cn} ${occasion.en} | 朝代 Dynasty: ${dynasty.cn} ${dynasty.en}`;
        // 排序 Sorted by: ${sortByTextCn} ${sortByTextEn}
        setSelectedSummary(summary);
    }

    return (
        <div className="page-wrapper">
            <div className="filter-section-text">
                <h6>按季节，场合和朝代查找即将推出！请暂时先按排序查找 ✨</h6>
                <h6>Filter by Seasons, Occasions, and Dynasties coming soon! Meanwhile, please filter by Sort by ✨</h6>
            </div>
            <div className="filter-sort-container">
                <ProductFilter
                    onFilterChange={handleFilterChange}
                    onSelectionChange={handleSelectionChange}
                    filters={filters}
                />
                <ProductSort onSortChange={handleSortChange} currentSort={sortBy} />
            </div>
            <div className="selected-summary">{selectedSummary}</div>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {!loading && !error && <ProductGrid products={products} />}
            <div className="disclosure-container">
                <p className="auth-message">
                    <small>此页面上的某些链接是附属链接，符合资格的购买我们可能会赚取佣金。感谢您的支持！❤️
                    </small>
                </p>
                <p className="auth-message">
                    <small>Some links on this page are affiliate links, and qualifying purchases may earn us a commission. Thank you for your support! ❤️</small>
                </p>
            </div>
        </div>
    )
}

export default ProductsPage; 