import React from 'react';

const QRCode = () => {
    return (
        <div className="page-wrapper">
            <h5>汉服世界二维码 🧩</h5>
            <h5>Hanfu World QR Code 🧩</h5>
            <div className="qr-code-container">
                <img className="landing-image qrcode-img" src="/images/qr_code_square_transparent.png" alt="hanfuworld-qr-code" />
            </div>
        </div>
    )
}

export default QRCode; 