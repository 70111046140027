// src/pages/auth/Login.js 

import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import UserContext from '../../context/UserContext';
import { Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash, FaGoogle, FaTiktok, FaInstagram, FaXTwitter, FaDiscord } from "react-icons/fa";
import '../../css/Auth.css';

const Login = () => {
    // Setting API base URL
    const BACKEND_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const [loading, setLoading] = useState(false);

    // Use useContext to access loginUser
    const { loginUser } = useContext(UserContext); // Use useContext to access loginUser
    const navigate = useNavigate(); // Instantiate useNavigate

    const handleChangeEmail = (event) => setEmail(event.target.value);
    const handleChangePassword = (event) => setPassword(event.target.value);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const signupLinkCn = <Link to="/signup" className="auth-link">这里注册</Link>
    const signupLinkEn = <Link to="/signup" className="auth-link">Signup here</Link>


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Enable spinner
        setLoading(true);
        try {
            // Update with backend URL:
            const response = await fetch(`${BACKEND_BASE_URL}/auth/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });

            // Assuming the server responds with user data on successful signup
            const data = await response.json();

            if (response.ok) {
                // loginUser(data); // Update the user state in context
                console.log("data user profile photo url", data.profilePhoto); 

                loginUser({
                    token: data.token,
                    userId: data.userId, 
                    username: data.username, 
                    profilePhoto: data.profilePhoto,
                });
                setResponseMessage('Welcome back! 😍');
                setEmail('');
                setPassword('');

                // navigate('/create'); // Navigate to the homepage or user's profile page
                // Redirect or change application state upon successful login
                // history.push('/dashboard'); // Example using react-router
                localStorage.setItem('hasSignedUp', 'true'); // Set hasSignedUp to true
                const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/create'; 
                localStorage.removeItem('redirectAfterLogin'); 
                navigate(redirectUrl);
            } else {
                // Handle server errors (like email already in use)
                console.log(data);
                // const signupLink = <Link to="/signup" className="auth-link">Signup</Link>
                // setResponseMessage(data.message || 'Login unsuccessful. Please try again.');
                setResponseMessage(
                    // <span>Looks like this email has not signed up yet 😅. Try {signupLink} instead.</span>
                    <small>
                        <span>这个电子邮件似乎尚未注册 😅。 请试试在{signupLinkCn}。</span>
                        <span>Looks like this email has not signed up yet 😅. Try {signupLinkEn}.</span>
                    </small>
                )
            }
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Login unsuccessful. Please try again.')
        }
        // Disable spinner
        setLoading(false);
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-container">
                <div className="branding">
                    {/* <h2>Hanfu World</h2> */}
                    <div className="auth-promo">
                        <img className="auth-img" src="/images/auth_image_2.png" alt="brand-promo" />
                    </div>
                </div>
                <div className="auth-form-container">
                    <h3 className="auth-form-header">🔑 登陆</h3>
                    <h3 className="auth-form-header">Log In</h3>
                    {/* <small>没有账号吗？{signupLinkCn}。</small>
                    <small>Don't have an account yet? {signupLinkEn}.</small>
                    {responseMessage && <p className="auth-message">{responseMessage}</p>} */}
                    {responseMessage ?
                        <p className="auth-message">{responseMessage}</p>
                        :
                        <p className="auth-message">
                            <small>还没有账号吗？{signupLinkCn}。</small>
                            <small>Don't have an account yet? {signupLinkEn}.</small>
                        </p>
                    }
                    <form className="auth-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email" className="form-label">电子邮件 Email</label>
                            <input type="email" className="form-control" name="email" value={email} onChange={handleChangeEmail} placeholder="name@example.com" required />
                        </div>
                        <div className="form-group password-group">
                            <label htmlFor="password" className="form-label">密码 Password</label>
                            <div className="password-container">
                                <input type={showPassword ? "text" : "password"} className="form-control" name="password" value={password} onChange={handleChangePassword} placeholder="********" required />
                                <i className="form-password-icon" onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </i>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-dark btn-auth">
                            {/* 登陆 Login */}
                            {loading ?
                                <div>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="auth-spinner-text">登陆中 Logging in...❤️</span>
                                </div>
                                :
                                '登陆 Login'}
                        </button>
                        {/* <p>Or Continue with</p>
                        <button className="btn btn-light btn-oauth"><FaGoogle className="auth-icon" />
                            <span className="auth-company">谷歌 Google</span>
                        </button> */}
                    </form>
                    <small>
                        点击登陆即表示您同意我们的{' '}
                        <a href="/terms" target="_blank" rel="noopener noreferrer">服务</a> 和{' '}
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">隐私</a> 条款。
                    </small>
                    <small>
                        By clicking Login, you agree to our{' '}
                        <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> and{' '}
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                    </small>

                </div>
            </div >
        </div>
    )
};

export default Login;

