// src/components/TitleGrid.js; 
import React from 'react';
import ProductCard from './ProductCard';
import '../../css/TitleGrid.css';


const ProductGrid = ({ products }) => {

    return (
        <div className="products-grid">
            {products.map((product, index) => (
                <ProductCard key={product.product_id} product={product} />
            ))}
        </div>
    )
}

export default ProductGrid;