import React, { useState } from 'react';
import '../../css/Pricing.css';

const plans = [
    {
        id: 'newbie',
        // icon: '👶🏻',
        icon: '👋🏻',
        title_cn: '汉服新手',
        title_en: 'Hanfu Newbie',
        // priceMonthly: 0,
        // priceYearly: 0,
        free: true,
        available: true,
        features: [
            '注册即可免费获得一张生成AI汉服图片的代币！ 🙌🏻',
            'One free token to generate AI Hanfu image when you signup! 🙌🏻',
            '观看所有影视片、创建和编辑个人收藏列表和观看列表',
            'Access to all titles, create & edit personal favoritelist & watchlists',
            '获得汉服世界推荐的精选产品',
            'Access to curated Hanfuworld shop products',
            // 'Earn AI tokens forreviewing titles (coming soon)'
        ], 
        color: 'white'
    },
    {
        id: 'lover',
        // icon: '👧🏻',
        icon: '❤️',
        title_cn: '汉服爱好者',
        title_en: 'Hanfu Lover',
        priceMonthly: 9.99,
        priceYearly: 99,
        savings: 20,
        free: false,
        available: true,
        addition: [
            '汉服新手的一切，加上：',
            'Everything in Hanfu Newbie, plus:',
        ],
        features: [
            '每月 20 个用于 AI 图像生成的代币',
            '20 tokens for AI image generation per month!',
            '汉服世界商店产品的促销代码和折扣',
            'Promo codes & discounts for Hanfuworld shop products',
            '24/7 客户和服务支持',
            '24/7 customer support',
        ], 
        color: 'linear-gradient(to right, rgba(208, 236, 246, 1) 0%, rgba(208, 236, 246, 0.1) 100%)'
    },
    // {
    //     id: 'superfan',
    //     // icon: '🙋🏻‍♀️',
    //     icon: '🪭',
    //     title_cn: '汉服粉丝！',
    //     title_en: 'Hanfu Superfan!',
    //     priceMonthly: 19.99,
    //     priceYearly: 199,
    //     savings: 40,
    //     free: false,
    //     available: false,
    //     addition: [
    //         '汉服爱好者的一切，加上：',
    //         'Everything in Hanfu Lover, plus:',
    //     ],
    //     features: [
    //         '每月 50 个用于 AI 图像生成的代币',
    //         '50 tokens for AI image generation per month!',
    //         '汉服世界商店产品专享折扣',
    //         'Exclusive discounts for Hanfuworld shop products',
    //         '参加汉服世界活动（即将推出）',
    //         'Access to Hanfuworld events (coming soon)'
    //     ]
    // },
];

const PricingChart = () => {
    const [isMonthly, setIsMonthly] = useState(true);
    // const [isFree, setIsFree] = useState(true);

    return (
        <div>
            {/* <p>Join the #1 online community for everything Hanfu! ❤️
            </p> */}
            <div className="toggle-buttons">
                <button onClick={() => setIsMonthly(true)} className={isMonthly ? 'active' : ''}>按月 Monthly</button>
                <button onClick={() => setIsMonthly(false)} className={!isMonthly ? 'active' : ''}>按年 Yearly</button>
            </div>
            <div className="plans">
                {plans.map((plan) => (
                    <div className="plan" key={plan.id} style={{background: `${plan.color}`}}>
                        <span className="plan-icon">{plan.icon}</span>
                        <h3 className="plan-title">{plan.title_cn}</h3>
                        <h3 className="plan-title">{plan.title_en}</h3>
                        <h4>
                            <button className="plan-pricing" >
                                {plan.free ? "免费 FREE!" : `${isMonthly ? `$${plan.priceMonthly} /月 month` : `$${plan.priceYearly} /年 year`}`}
                                {/* {plan.free ? "FREE" : `${isMonthly ? `$${plan.priceMonthly}` : `$${plan.priceYearly}`}`} */}
                            </button>
                        </h4>
                        {!plan.free && !isMonthly &&
                            <p className="savings">Save ${plan.savings}</p>
                        }
                        <div className="plan-texts">
                            {plan.addition && plan.addition.map((a, index) => (
                                <span className="plan-text" key={index}>{a}</span>
                            ))}
                        </div>
                        <ul>
                            {plan.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>

                        {plan.free ?
                            <a href="/signup" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-light btn-free">
                                    开始 Get Started
                                </button>
                            </a>
                            :
                            <a href={isMonthly ? "https://buy.stripe.com/14k5kBa51eHC6xG8ww" : "https://buy.stripe.com/cN24gx1yvczu2hqcMN"} target="_blank" rel="noopener noreferrer" >
                                <button className="btn btn-light btn-pricing">
                                    立即加入 Join Now!
                                </button>
                            </a>
                        }

                        {/* {plan.available ?
                            <a href={plan.free ? "/signup" : "/watch/trending"} target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-light btn-pricing">
                                    Get Started
                                </button>
                            </a>
                            :
                            <a href={plan.free ? "/signup" : "/watch/trending"} target="_blank" rel="noopener noreferrer" >
                                <button className="btn btn-light btn-coming">
                                    Coming Soon!
                                </button>
                            </a>
                        } */}
                    </div>
                ))}
            </div>
        </div >
    );
};

export default PricingChart;
