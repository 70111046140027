// src/components/experience/EventGrid.js; 
import React from 'react';
import EventCard from './EventCard';
import '../../css/TitleGrid.css';


const EventList = ({ events }) => {

    return (
        <div className="events-list">
            {events.map((event, index) => (
                <EventCard key={event.event_id} event={event} />
            ))}
        </div>
    )
}

export default EventList;