// src/components/UserProfileForm.js

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { BsPersonCircle } from "react-icons/bs";
import '../../css/Page.css';
import '../../css/UserProfile.css';

const UserProfileForm = ({ username }) => {
    const { user, updateUser } = useContext(UserContext);
    const navigate = useNavigate();

    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        profilePhoto: '',
        birthday: '',
        currentCity: '',
        currentCountry: '',
        bio: '',
    });

    const [uploadProfilePhoto, setUploadProfilePhoto] = useState('');
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const BACKEND_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

    useEffect(() => {
        // console.log('Username in UserProfileForm:', username);
        fetchProfile();
    }, [username]);

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
    };

    const fetchProfile = async () => {
        try {
            const response = await fetch(`${BACKEND_BASE_URL}/api/users/${username}/profile`);
            const data = await response.json();
            if (response.ok) {
                setProfileData({
                    firstName: data.first_name || '',
                    lastName: data.last_name || '',
                    profilePhoto: data.profile_photo_url || '',
                    birthday: formatDate(data.birthday) || '',
                    currentCity: data.current_city || '',
                    currentCountry: data.current_country || '',
                    bio: data.self_intro || '',
                });
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check the file size (< 5 MB) and type (jpeg or png)
            if (file.size > 2 * 1024 * 1024) {
                alert("Please upload a file smaller than 2MB or 1024 x 1024 ❤️.");
                return; 
            }

            if (!file.type.match('image/jpeg') && !file.type.match('image/png')) {
                alert("Invalid file tyle. Only JPEG and PNG are allow."); 
                return; 
            }

            setUploadProfilePhoto(file);
            const previewUrl = URL.createObjectURL(file);
            setProfilePhoto(previewUrl); // Preview the image before uploadv
        }
    };

    const handleProfilePhotoUpload = async () => {
        if (!uploadProfilePhoto) return;

        setLoading(true);
        setResponseMessage('');

        const formData = new FormData();
        formData.append('profilePhoto', uploadProfilePhoto);

        try {
            const response = await fetch(`${BACKEND_BASE_URL}/api/users/${username}/profile/profilePhoto`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                setResponseMessage('Profile photo updated successfully.');
                setProfileData((prevData) => ({
                    ...prevData,
                    profilePhoto: data.profile_photo_url,
                }));
                updateUser((prevUser) => ({
                    ...prevUser,
                    profile: {
                        ...prevUser.profile,
                        profile_photo_url: data.profile_photo_url,
                    },
                }));
                setUploadProfilePhoto(null); // Clear the file after successful upload
                // setProfilePhoto(data.profile_photo_url); 
            } else {
                setResponseMessage(data.message || 'Failed to update profile photo.');
            }
        } catch (error) {
            console.error('Error uploading profile photo:', error);
            setResponseMessage('An error occurred while uploading the profile photo.');
        }
        setLoading(false);
    };

    const handleDrop = useCallback((event) => {
        event.preventDefault(); 
        const file = event.dataTransfer.files[0]; 
        if (file) {
            setUploadProfilePhoto(file); 
            const previewUrl = URL.createObjectURL(file); 
            setProfilePhoto(previewUrl); 
        }
    }, []); 

    const handleDragOver = (event) => {
        event.preventDefault(); 
    }; 

    const fieldMapping = {
        firstName: 'first_name',
        lastName: 'last_name',
        profilePhoto: 'profile_photo_url',
        birthday: 'birthday',
        currentCity: 'current_city',
        currentCountry: 'current_country',
        bio: 'self_intro'
    };

    const handleUpdate = async (field, value) => {
        console.log('Updating field:', field, 'with value:', value);

        setLoading(true);
        setResponseMessage('');
        const mappedField = fieldMapping[field] || field;
        try {
            const response = await fetch(`${BACKEND_BASE_URL}/api/users/${username}/profile/${mappedField}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify({ value: value }),
            });

            const data = await response.json();

            if (response.ok) {
                setResponseMessage(`${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully.`);
                setProfileData(prevData => ({
                    ...prevData,
                    [field]: value
                }));

                updateUser(prevUser => ({
                    ...prevUser,
                    profile: {
                        ...prevUser.profile,
                        [mappedField]: value
                    }
                }));
            } else {
                setResponseMessage(data.message || `Failed to update ${field}.`);
            }
        } catch (error) {
            console.error(`Error updating ${field}:`, error);
            setResponseMessage(`An error occurred while updating ${field}.`);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e, field) => {
        e.preventDefault();
        handleUpdate(field, profileData[field]);
    };


    return (
        <div className="account-form-wrapper">
            <h5>我的资料 🤖</h5>
            <h5>My Profile 🤖</h5>
            <div className="account-form-container">
                <div className="photo-container"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                >
                    {profilePhoto || profileData.profilePhoto ?
                        <img className="profile-photo" src={profilePhoto || profileData.profilePhoto} alt="profile-photo" />
                        :
                        <BsPersonCircle className="profile-photo-icon" />
                    }
                    <label className="btn btn-light btn-profile">
                        {profileData.profilePhoto ? '更新头像 Update profile photo' : '上传头像 Upload a profile photo'}
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleProfilePhotoChange}
                        />
                    </label>
                </div>
                {uploadProfilePhoto && (
                    <button
                        className="btn btn-light btn-save-profile"
                        onClick={handleProfilePhotoUpload}
                        disabled={loading}
                    >
                        {loading ? '上传中 Uploading...' : '保存 Save'}
                    </button>
                )}
                {responseMessage && <p className="response-message">{responseMessage}</p>}

                <form className="account-form" onSubmit={(e) => handleSubmit(e, 'firstName')}>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label className="form-label">
                                名字 First Name:
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id='firstName'
                                name="firstName"
                                value={profileData.firstName}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-profile" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>
                <form className="account-form" onSubmit={(e) => handleSubmit(e, 'lastName')} >
                    <div className="form-group-container">
                        <div className="form-group">
                            <label className="form-label">
                                姓氏 Last Name:
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={profileData.lastName}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-profile" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>
                <form className="account-form" onSubmit={(e) => handleSubmit(e, 'birthday')}>

                    <div className="form-group-container">

                        <div className="form-group">
                            <label className="form-label">
                                生日 Birthday:
                            </label>
                            <input
                                className="form-control"
                                type="date"
                                id="birthday"
                                name="birthday"
                                value={profileData.birthday}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-profile" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>
                <form className="account-form" onSubmit={(e) => handleSubmit(e, 'currentCity')}>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label className="form-label">
                                城市 Current City:
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id="currentCity"
                                name="currentCity"
                                value={profileData.currentCity}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-profile" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>
                <form className="account-form" onSubmit={(e) => handleSubmit(e, 'currentCountry')}>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label className="form-label">
                                国家 Current Country:
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id="currentCountry"
                                name="currentCountry"
                                value={profileData.currentCountry}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-profile" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>
                <form className="account-form" onSubmit={(e) => handleSubmit(e, 'bio')}>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label className="form-label">
                                介绍 Bio:
                            </label>
                            <textarea
                                className="form-control input-textarea"
                                type="text"
                                id="bio"
                                name="bio"
                                rows="3"
                                placeholder="向我们介绍自己 Tell us about who you are! :)"
                                value={profileData.bio}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-profile" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>

            </div>

        </div>
    );

};

export default UserProfileForm; 
