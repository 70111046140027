// src/components/create/CreateWrapper.js 

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import GenerateImage from './GenerateImage';

function CreateWrapper() {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const hasSignedUp = localStorage.getItem('hasSignedUp');

    // console.log(user.userId);

    useEffect(() => {
        // Checks if the user object is present and has a userId
        if (user?.userId) {
            // Set the hasSignedUp flag if it's not already set
            if (!hasSignedUp) {
                localStorage.setItem('hasSignedUp', 'true'); 
            }
            // Navigate only if not already on the correct path to avoid unnecessary redirects
            if (!window.location.pathname.includes(`/create/users/${user.userId}`)) {
            navigate(`/create/users/${user.userId}`);
            }
        } else {            
            // Redirect based on the 'hasSignedUp' flag stored in localStorage
            // if the user is not logged in 
            if (hasSignedUp === 'true') {
                // If they have signed up, go to login
                navigate('/login');
            } else {
                navigate('/signup'); // Otherwsie, go to signup 
            }
        }
    }, [user, hasSignedUp, navigate]);

    if (!user) {
        return <div>Loading...</div>
    }
    return <GenerateImage userId={user?.userId}/>
}

export default CreateWrapper; 