import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaStar } from 'react-icons/fa';
import '../../css/Page.css';
import '../../css/Rating.css';

const StarRating = ({ titleId, user, initialRating, averageRating, onRatingSubmit, navigate }) => {
    const [userRating, setUserRating] = useState(initialRating || 0);
    const [hover, setHover] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const containerRef = useRef(null);

    useEffect(() => {
        setUserRating(initialRating || 0);
    }, [initialRating]);

    const handleRating = useCallback(async (ratingValue) => {

        const scaledRating = ratingValue * 2;
        setUserRating(scaledRating);
        setIsSubmitting(true);
        setPopupMessage('提交中 Submitting...');
        setShowPopup(true);

        try {
            const success = await onRatingSubmit(scaledRating);
            if (success) {
                setPopupMessage('Rating recorded. Thank you! 😄')
            } else {
                setPopupMessage('Rating submission failed. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
            setPopupMessage('Failed to submit rating. Please try again.');
        } finally {
            setIsSubmitting(false);
            setTimeout(() => setShowPopup(false), 1500);
        }
    }, [user, navigate, onRatingSubmit]);

    const getStarFillPercentage = useCallback((starPosition) => {
        const filledStars = isHovering ? hover : averageRating / 2;
        if (starPosition <= filledStars) {
            return '100%';
        } else if (starPosition - 1 < filledStars) {
            return `${(filledStars % 1) * 100}%`;
        }
        return '0%';
    }, [hover, averageRating, isHovering]);

    const handleMouseEnter = useCallback(() => {
        setIsHovering(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsHovering(false);
        setHover(null);
    }, []);

    const handleStarHover = useCallback((starPosition) => {
        setHover(starPosition);
    }, []);

    return (
        <div 
            className="star-rating-container" 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
            ref={containerRef}
        >
            {[...Array(5)].map((star, i) => {
                const starPosition = i + 1;
                return (
                    <label key={i} className="star-label">
                        <input
                            type="radio"
                            name="rating"
                            value={starPosition}
                            onClick={() => handleRating(starPosition)}
                            className="star-input"
                        />
                        <div className="star-fill" style={{ width: getStarFillPercentage(starPosition) }}>
                            <FaStar 
                                className="star-icon filled"
                                size={22}
                            />
                        </div>
                        <FaStar
                            className={`star-icon ${isHovering && starPosition <= hover ? 'hovered' : ''}`}
                            size={22}
                            onMouseEnter={() => handleStarHover(starPosition)}
                        />
                    </label>
                );
            })}
            {isHovering && hover !== null && (
                <span className="rating-hover">
                    {hover * 2}
                </span>
            )}
            {showPopup && (
                <div className="rating-popup">
                    {popupMessage}
                </div>
            )}
        </div>
    );
};

export default StarRating;