// src/components/watch/WatchlinkPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Watchlinks from './Watchlinks';

const WatchlinkPage = () => {

    const { titleId, watchlinkId } = useParams();
    const location = useLocation();
    const [watchlinkDetails, setWatchlinkDetails] = useState({});
    const [titleDetails, setTitleDetails] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);

    const BACKEND_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
    // access the episode from state
    //  const watchlinkId = location.state?.episode?.watchlink_id || episodeId;
    console.log(watchlinkId)
    // const watchlinkId = episodeId;
    // console.log(watchlinkId);

    const WATCHLINK_API_URL = BACKEND_BASE_URL + `/api/watch/titles/${titleId}/watchlinks/${watchlinkId}`
    const TITLE_API_URL = process.env.REACT_APP_API_BASE_URL + `/api/watch/titles/${titleId}` || `http://localhost:3000/api/watch/titles/${titleId}`;

    useEffect(() => {
        // If watchlinkDetails is not already set by the Link state, fetch it
        // console.log(WATCHLINK_API_URL);
        const fetchWatchlinkDetails = async () => {
            try {
                const response = await fetch(WATCHLINK_API_URL);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setWatchlinkDetails(data);
            } catch (error) {
                console.error('Error fetching watchlink details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchWatchlinkDetails();
    }, [WATCHLINK_API_URL]);

    useEffect(() => {
        // Fetching the details of the title 
        fetch(TITLE_API_URL)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                } return response.json();
            })
            .then(data => {
                setTitleDetails(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the title details:', error);
                setError(error);
                setLoading(false);
            });
        // Dependency array with titleId to re-run useEffect when titleId changes
    }, [titleId]);

    if (loading) {
        return <div>Loading...</div>
    }

    if (!watchlinkDetails) {
        return <div>Coming soon...</div>
    }

    return (
        <div className="page-wrapper watchlink-page">
            {watchlinkDetails.watchlink_url && (
                <div className="title-video-responsive">
                    <iframe
                        className="title-embed-video"
                        // 1.6 aspect ration (macbook pro screen ratio)
                        width="900"
                        height="560"
                        src={`${watchlinkDetails.watchlink_url}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    {/* <div className="title-container">
                        <a href={`/watch/titles/${titleId}`} className="title-link-container" target="_blank" rel="noopener noreferrer">
                            <h5 className="watchlink-title">{titleDetails.title_name_cn}</h5>
                            <h5 className="watchlink-title">{titleDetails.title_name_en}</h5>
                        </a>
                    </div> */}
                    <div className="title-details-container">
                        <a href={`/watch/titles/${titleId}`} className="title-link-container" target="_blank" rel="noopener noreferrer">
                            <h5 className="watchlink-title">{titleDetails.title_name_cn}</h5>
                            <h5 className="watchlink-title">{titleDetails.title_name_en}</h5>
                        </a>
                        <div className="episode-container">
                            <h6 className="watchlink-episode">第 {watchlinkDetails.watchlink_episode} 集</h6>
                            <h6 className="watchlink-episode">Episode {watchlinkDetails.watchlink_episode}</h6>
                        </div>
                    </div>
                    <div className="watchlink-actions">
                        <a href="/report" className="report-link" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-light btn-report">Report link not working</button>
                        </a>
                    </div>
                </div>
            )}


            <div className="watchlinks-section">
                <Watchlinks titleId={titleDetails.title_id} />
            </div>

        </div>
    )
}

export default WatchlinkPage; 