// src/pages/auth/Signup.js 

import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash, FaGoogle, FaTiktok, FaInstagram, FaXTwitter, FaDiscord } from "react-icons/fa";
import '../../css/Auth.css';

const Signup = () => {
    // Setting API base URL
    const BACKEND_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const [loading, setLoading] = useState(false);

    // Use useContext to access loginUser
    const { loginUser } = useContext(UserContext); // Assuming loginUser is available in your context
    const navigate = useNavigate(); // Instantiate useNavigate

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const loginLinkCn = <Link to="/login" className="auth-link">这里登陆</Link>;
    const loginLinkEn = <Link to="/login" className="auth-link">Login here</Link>;


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Enable spinner
        setLoading(true);
        try {
            // Update with backend URL:
            const response = await fetch(`${BACKEND_BASE_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            });

            // Assuming the server responds with user data on successful signup
            const data = await response.json();

            if (response.ok) {
                // loginUser(data); // Log in the user in context
                loginUser({ 
                    token: data.token, 
                    userId: data.userId, 
                    username: data.username, 
                    profilePhoto: data.profilePhoto
                }); // Calling loginUser with the correct data
                setResponseMessage('Yay! You are signed up! 😍');
                setEmail('');
                setPassword('');
                localStorage.setItem('hasSignedUp', 'true'); // Set hasSignedUp to true
                // navigate('/create');
                const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/create'; 
                localStorage.removeItem('redirectAfterLogin'); 
                navigate(redirectUrl); 
            } else if (response.status === 409) {
                setResponseMessage(
                    <small>
                    <span>这个电子邮件似乎已经注册 😅。 请试试在{loginLinkCn}。</span>
                    <span>Looks like this email had already signed up 😅. Try {loginLinkEn}.</span>
                </small>
                )
            } else {
                setResponseMessage(`Signup unsuccessful: ${data.message}`);
                // Handle server errors (like email already in use)
                // console.log(data); // Debugging
                // const loginLink = <Link to="/login" className="auth-link">login</Link>;
                // setResponseMessage(data.message || 'Looks like this email had already signed up 😅. Try login instead.');
                // setResponseMessage(
                    // <span className="auth-message">{data.message} Looks like this email had already signed up 😅. Try {loginLink} instead.</span>
                    // <span className="auth-message">Looks like this email had already signed up 😅. Try {loginLink} instead.</span>
                // )
            }
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Signup unsuccessful. Please try again.');
        }
        // Disable spinner
        setLoading(false);
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-container">
                <div className="branding">
                    {/* <h2>Hanfu World</h2> */}
                    <div className="auth-promo">
                        <img className="auth-img" src="/images/auth_image_1.png" alt="brand-promo" />
                    </div>
                </div>
                <div className="auth-form-container">
                    {/* Makes POST request to /register route */}
                    <h3 className="auth-form-header">🏠 注册</h3>
                    <h3 className="auth-form-header">Sign Up</h3>
                    {/* {responseMessage && <p className="auth-message">{responseMessage}</p>} */}
                    {responseMessage ?
                        <p className="auth-message">{responseMessage}</p>
                        :
                        <p className="auth-message">
                            <small>已经注册了账号？{loginLinkCn}。</small>
                            <small>Already have an account? {loginLinkEn}.</small>
                        </p>
                    }
                    <form className="auth-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email" className="form-label" >电子邮件 Email</label>
                            <input type="email" className="form-control" name="email" value={email} onChange={handleChangeEmail} placeholder="name@example.com" required />
                        </div>
                        <div className="form-group password-group">
                            <label htmlFor="password" className="form-label">密码 Password</label>
                            <div className="password-container">
                                <input type={showPassword ? "text" : "password"} className="form-control" name="password" value={password} onChange={handleChangePassword} placeholder="********" required></input>
                                <i className="form-password-icon" onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </i>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-dark btn-auth" disabled={loading}>
                            {/* 注册 Signup */}
                            {loading ?
                                <div>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    <span className="auth-spinner-text">创建中 Creating account...❤️</span>
                                </div> : '注册 Signup'}
                        </button>
                        {/* <p>Or Continue with</p>
                        <button className="btn btn-light btn-oauth"><FaGoogle className="auth-icon" />
                            <span className="auth-company">谷歌 Google</span>
                        </button> */}
                    </form>
                    <small>
                        点击注册即表示您同意我们的{' '}
                        <a href="/terms" target="_blank" rel="noopener noreferrer">服务</a> 和{' '}
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">隐私</a> 条款.
                    </small>
                    <small>
                        By clicking Signup, you agree to our{' '}
                        <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> and{' '}
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                    </small>
                </div>
            </div>
        </div>
    )
};

export default Signup;

