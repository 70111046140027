import React, { useState } from 'react';
import '../../css/Learn.css';

const terms = [
    {
        id: '1',
        term_icon: 'hanfu.png',
        term_cn: '汉服',
        term_en: 'Hanfu',
        term_def: [
            '汉服是什么?',
            '汉服是汉民族的传统服饰。',
            '',
            'What is Hanfu?',
            "Hanfu is the traditional clothing of the Han Chinese, the majority ethnic group in China and worldwide.",
            '',
            '汉服的历史是什么?',
            '汉服有5千多年的历史。在史书《后汉书·舆服制》中，范晔记载汉服“始于黄帝（公元前2717年），备于尧舜”。其他有关“汉服”一词的最早官方书面记载之一是在东汉时期（公元前202年-公元220年）蔡邕（132-192年）所著的《独断》中。',
            '',
            'What is the history of Hanfu?',
            'Hanfu has over 5000 years of history. In the ancient Chinese history text "后汉书", Book of the Later Han, Fan Ye wrote Hanfu was "originated with the Yellow Emperor (2717 BC), perfected by Yao and Shun". Another of the earliest official written records of the word "Hanfu" is found in "独断", Du Duan, written by Cai Yong (132–192) during the Eastern Han Dynasty (202 BC-220 AD).',
            '',
            '汉服与其他民族服饰，比如和服和韩服，有什么区别？',
            '汉服影响了其他亚洲民族服饰，包括和服，韩服，越服，等等。',
            '',
            'What is the difference between Hanfu, Kimono, Hanbok, etc.?',
            'Hanfu has influenced other Asian cultural clothing, including the Japanese Kimono, Korean Hanbok, Vietnamese áo giao lĩnh, among others.',
        ],
        term_link: "/shop",
    },
    {
        id: '2',
        term_icon: 'cdrama.png',
        term_cn: '连续剧',
        term_en: 'CDrama',
        term_def: [
            'CDrama 是什么?',
            '中文电视剧或连续剧在英文中通常被称为"CDrama" 或 "C-Drama"。',
            '',
            'What is CDrama?',
            'CDrama or C-Drama = Chinese Drama.',
            '',
            'CDrama 包括香港连续剧和台湾连续剧吗?',
            'CDrama 通常指中文连续剧，不仅包括中国大陆所制作的连续剧，也包括香港连续剧和台湾连续剧。很多中文连续剧也是由中国大陆，香港，台湾，和/或其他地区的制作团队一起合作完成。大量中文连续剧包括香港连续剧和台湾连续剧里的演员也有很多两岸三地和海外的华人。',
            '',
            'Do CDramas include Hong Kong or Taiwan dramas?',
            'CDramas in general terms usually mean Chinese language dramas, not just dramas made by mainland China, but also other Chinese speaking regions as well. In addition, many Chinese dramas are made in collaborations by mainland China, Hong Kong, and Taiwan teams, and a lot of actors and actresses in Chinese dramas are from different regions including mainland China, Hong Kong, Taiwan, and other overseas regions.',
        ],
        term_link: "/watch/cdramas",
    },
    {
        id: '3',
        term_icon: 'cmovie.png',
        term_cn: '电影',
        term_en: 'CMovie',
        term_def: [
            'CMovie 是什么?',
            '中文电影在英文中通常被称为"CMovie" 或 "C-Movie"。',
            '',
            'What is CMovie?',
            'CMovie or C-Movie = Chinese Movie.',
            '',
            'CMovie 包括香港电影和台湾电影吗?',
            'CMovie 通常指中文电影，不仅包括中国大陆所制作的电影，也包括香港电影和台湾电影。很多中文电影也是由中国大陆，香港，台湾，和/或其他地区的制作团队一起合作完成。大量中文电影包括香港电影和台湾电影里的演员也有很多两岸三地和海外的华人。',
            '',
            'Do CMovies include Hong Kong or Taiwan movies?',
            'CMovies in general terms usually mean Chinese language movies, not just movies made by mainland China, but also other Chinese speaking regions as well. In addition, many Chinese movies are made in collaborations by mainland China, Hong Kong, and Taiwan teams, and a lot of actors and actresses in Chinese movies are from different regions including mainland China, Hong Kong, Taiwan, and other overseas regions.',
        ],
        term_link: "/watch/cmovies",
    },
    {
        id: '4',
        term_icon: 'donghua.png',
        term_cn: '动画',
        term_en: 'Donghua',
        term_def: [
            'Donghua 是什么?',
            '中国动画在英文中通常被称为"Donghua"。',
            '',
            'What is Donghua?',
            'Donghua = Chinese animation. “Dong" is the Pinyin for the Chinese character "动", meaning animation or moving, "hua" is the Pinyin for the Chinese character "画", meaning picture or photo.',
            '',
            'Donghua 和 Anime, Donghwa 有什么区别？',
            '中国动画在英文中通常被称为"Donghua"，日本动画在英文中通常被称为 "Anime", 韩国动画在英文中通常被称为 "Korean Anime or Donghwa"。',
            '',
            'What is the difference among Donghua, Anime, and Donghwa?',
            'Donghua = Chinese animation, Anime = Japanese animation, Donghwa = Korean Anime or Korean animation.',
        ],
        term_link: "/watch/donghuas",
    },
    {
        id: '5',
        term_icon: 'manhua.png',
        term_cn: '漫画',
        term_en: 'Manhua',
        term_def: [
            'Manhua 是什么?',
            '中国漫画在英文中通常被称为"Manhua"。',
            '',
            'What is Manhua?',
            'Manhua = Chinese comics. “Man" is the Pinyin for the Chinese character "漫", meaning free or random (in this context), "hua" is the Pinyin for the Chinese character "画", meaning picture or photo.',
            '',
            'Manhua 和 Manga, Manhwa 有什么区别？',
            '中国漫画在英文中通常被称为"Manhua"，日本漫画在英文中通常被称为 "Manga", 韩国漫画在英文中通常被称为 "Manhwa"。',
            '',
            'What is the difference among Manhua, Manga, and Manhwa?',
            'Manhua = Chinese comics, Manga = Japanese comics, Manhwa = Korean comics.',
        ],
        term_link: "/watch/manhuas",
    },
]

const FAQ = () => {
    // Default to the first term
    const [activeTab, setActiveTab] = useState(terms[0].id);

    return (
        <div className="section-container">
            <h2 className="section-header">常问问题</h2>
            <h2 className="section-header">FAQs</h2>
            <div className="tabs">
                {terms.map((term) => (
                    <button
                        key={term.id}
                        className={`tab btn-term ${activeTab === term.id ? 'active' : ''}`}
                        onClick={() => setActiveTab(term.id)}
                    >
                        {term.term_cn} {term.term_en}
                    </button>
                ))}
            </div>
            <div className="term-content">
                {terms.map((term) => (
                    activeTab === term.id && (
                        <div className="term" key={term.id}>
                            <div className="term-container">
                                <div className="term-left">
                                    <a href={term.term_link} target="_blank" rel="noopener noreferrer">
                                        <img className="term-image" src={`/images/landing/${term.term_icon}`} alt={term.term_en} />
                                    </a>
                                </div>
                                <div className="term-right">
                                    <a href={term.term_link} target="_blank">
                                        <button className="btn btn-light btn-landing btn-landing-4">
                                            <h5 className="term-term">{term.term_cn}</h5>
                                            <h5 className="term-term">{term.term_en}</h5>
                                        </button>
                                    </a>
                                    <ul>
                                        {term.term_def.map((def, index) => (
                                            <li className="term-def" key={index}>{def}</li>
                                        ))}
                                    </ul>
                                    <a href={term.term_link} target="_blank">
                                        <button className="btn btn-light btn-term">
                                            看看 Explore
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                ))}
            </div>
        </div>
    )
}

export default FAQ; 