import React from 'react'; 
import ContactForm from './ContactForm'; 
import '../../css/Contact.css';


const Contact = () => {
    return (
        <div className="page-wrapper">
            <ContactForm />
        </div>
    )
}

export default Contact; 