import React from 'react'; 

const Cancel = () => {
    return (
        <div>
            Cancelled!
        </div>
    )
}

export default Cancel; 