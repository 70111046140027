import React, { useEffect, useState } from 'react'; 

function Success(props) {
    return (
        <div>
            Success!
        </div>
    )
}

export default Success; 