// src/components/shop/SearchProductDropdown.js
import React from 'react';
import '../../css/AppNavbar.css';

const SearchProductDropdown = ({ results, onSelect }) => {
    return (
        <div className="search-dropdown">
            {results.map((item, index) => (
                <div key={index} className="search-dropdown-item" onClick={() => onSelect(item)}>
                    <img src={item.product_image_url} alt={item.product_name_en} className="search-dropdown-image" />
                    <div className="search-dropdown-details">
                        <div>{item.product_title_cn}</div>
                        <div>{item.product_title_en}</div>
                        <div>${item.product_price_usd}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SearchProductDropdown; 