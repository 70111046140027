import React from 'react';
import { Link } from 'react-router-dom'; 
import "../../css/Page.css";

const Privacy = () => {

    return (
        <div className="page-wrapper">
            <div className="terms-container">
                <h5 className="page-subheader">
                    Hanfu World Privacy Policy
                </h5>
                <div className="terms-content-container">
                    <h6 className="terms-header">
                        Introduction
                    </h6>
                    <p className="terms-text">
                        At Hanfu World, your privacy is important to us. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to protect it.
                    </p>
                    <h6 className="terms-header">
                        Information Collection
                    </h6>
                    <p className="terms-text">
                        We collect information you provide to us when you create an account, post content, communicate with us, or interact with our services. This may include your name, email address, and any other information you provide.
                    </p>
                    <h6 className="terms-header">
                        Use of Information
                    </h6>
                    <p className="terms-text">
                        The information we collect is used to:
                        <ul>
                            <li>
                                Provide, maintain, and improve our services and improve user experience
                            </li>
                            <li>
                                Communicate with you about your account or services
                            </li>
                            <li>
                                Monitor and analyze trends, usage, and activities in connection with our services
                            </li>
                        </ul>
                    </p>
                    <h6 className="terms-header">
                        Sharing of Information
                    </h6>
                    <p className="terms-text">
                        We do not share your personal information with third parties except:
                        <ul>
                            <li>
                                With your consent
                            </li>
                            <li>
                                For legal reasons, including to comply with legal processes or to protect our rights and property or the safety of our users
                            </li>
                        </ul>
                    </p>
                    <h6 className="terms-header">
                        Data Security
                    </h6>
                    <p className="terms-text">
                        We value data security and take measures to protect your information from unauthorized access, alteration, or destruction.
                    </p>
                    <h6 className="terms-header">
                        Changes to Privacy Policy
                    </h6>
                    <p className="terms-text">
                        We may update this policy from time to time. We will notify you of any changes by posting the new policy on our website.
                    </p>
                    <h6 className="terms-header">
                        Contact Us
                    </h6>
                    <p className="terms-text">
                        If you have any questions about our privacy practices, please contact us <Link to="/contact">here</Link>.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Privacy;


