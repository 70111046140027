// src/components/user/UserSaves.js; 

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import TitleCard from '../watch/TitleCard';
import '../../css/UserProfile.css';
import '../../css/TitleGrid.css';

const UserSaves = () => {
    // const { userId } = useParams();
    const { username } = useParams();
    const { user } = useContext(UserContext);
    // console.log("UserId from URL:", userId); // This should show the userId from the URL
    console.log("Username from URL:", username); // This should show the username from the URL

    const [saves, setSaves] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchSaves = async () => {
            setLoading(true);
            setError('');

            // const USER_SAVES_API_URL = process.env.REACT_APP_API_BASE_URL + `/api/saves/users/${userId}` || `http://localhost:3000/api/saves/users/${userId}`;
            const USER_SAVES_API_URL = process.env.REACT_APP_API_BASE_URL + `/api/saves/users/${username}` || `http://localhost:3000/api/saves/users/${username}`;

            try {
                const response = await fetch(USER_SAVES_API_URL, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch saves');
                }
                const data = await response.json();
                // Assuming the API returns the saves directly.
                console.log(data);
                setSaves(data);
            } catch (err) {
                console.error("Failed to fetch saves:", err);
                setError('An error occured while fetching the saves.');
            } finally {
                setLoading(false);
            }
        };

        if (user) {
            fetchSaves();
        }
        // }, [userId, user]);
    }, [username, user]);

    if (loading) {
        return <div>Loading saves...</div>
    }
    if (error) {
        return <div className="error-message">{error}</div>
    }

    return (
        <div className="page-wrapper">
            <h5>我的收藏 🔖</h5>
            <h5>My Saves 🔖</h5>
            <div className="titles-grid">
                {saves.map((save) => (
                    <TitleCard
                        key={save.title_id}
                        title={save}
                    />
                ))}
            </div>
        </div>
    )
};

export default UserSaves; 