// src/components/TitleSort.js
import React from 'react';
import '../../css/Page.css';

const ProductSort = ({ onSortChange, currentSort }) => {
    // Placeholder for actual sort buttons
    return (
        <div className="sort-section">
            {/* Example sort buttons */}
            <h6 className="sort-header">
                <span>排序</span>
                <span>Sort by</span>
            </h6>
            <div className="sort-selection">
                <button
                    className={`btn btn-light btn-sort ${currentSort === 'added' ? 'btn-sort-active' : ''}`}
                    onClick={() => onSortChange('added')}>
                    添加 Added
                </button>
                <button
                    className={`btn btn-light btn-sort ${currentSort === 'views' ? 'btn-sort-active' : ''}`}
                    onClick={() => onSortChange('views')}>
                    最热 Views
                </button>
                <button
                    className={`btn btn-light btn-sort ${currentSort === 'rating' ? 'btn-sort-active' : ''}`}
                    onClick={() => onSortChange('rating')}>
                    评分 Rating
                </button>
                <button
                    className={`btn btn-light btn-sort ${currentSort === 'updated' ? 'btn-sort-active' : ''}`}
                    onClick={() => onSortChange('updated')}>
                    最新 Updated
                </button>
            </div>
        </div>
    )
}

export default ProductSort; 