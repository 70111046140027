// src/components/TitleFilter.js
import React, { useState, useEffect } from 'react';
import '../../css/Page.css';

const ProductFilter = ({ onFilterChange, onSelectionChange, filters }) => {

    const [seasons, setSeasons] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [dynasties, setDynasties] = useState([]);
    // const [years, setYears] = useState([]);

    useEffect(() => {

        const SEASONS_API_URL = process.env.REACT_APP_API_BASE_URL + "/api/shop/seasons" || 'http://localhost:3000/api/shop/seasons';
        const OCCASIONS_API_URL = process.env.REACT_APP_API_BASE_URL + "/api/shop/occasions" || 'http://localhost:3000/api/shop/occasions';
        const DYNASTIES_API_URL = process.env.REACT_APP_API_BASE_URL + "/api/shop/dynasties" || 'http://localhost:3000/api/shop/dynasties';

        
        // Fetch seasons 
        const fetchSeasons = async () => {
            const response = await fetch(SEASONS_API_URL);
            const data = await response.json();
            setSeasons([{ season_id: 'all', season_name_cn: '全部', season_name_en: 'All' }, ...data]);
            console.log(seasons);
        };

        // Fetch occassions 
        const fetchOccasions = async () => {
            const response = await fetch(OCCASIONS_API_URL);
            const data = await response.json();
            setOccasions([{ occasion_id: 'all', occasion_name_cn: '全部', occasion_name_en: 'All' }, ...data]);
        };

        // Fetch dynasties 
        const fetchDynasties = async () => {
            try {
                const response = await fetch(DYNASTIES_API_URL);
                const data = await response.json();
                // setDynasties([{ dynasty_id: 'all', dynasty_name_cn: '全部', dynasty_name_en: 'All' }, ...data]);
                if (response.ok) {
                    if (Array.isArray(data)) {
                        setDynasties([{ dynasty_id: 'all', dynasty_name_cn: '全部', dynasty_name_en: 'All' }, ...data]);
                    } else {
                        console.error('Data received is not an array:', data);
                        setDynasties([{ dynasty_id: 'all', dynasty_name_cn: '全部', dynasty_name_en: 'All' }]); // Set default or error state
                    }
                } else {
                    throw new Error('Failed to fetch dynasties: ' + data.message);
                }
            } catch (error) {
                console.error('Error fetching dynasties:', error);
            }
            // console.log(setDynasties);
        };

        // const generatedYears = () => {
        //     const currentYear = new Date().getFullYear();
        //     const yearsArray = Array.from({ length: currentYear - 1999 }, (_, i) => `${currentYear - i}`);
        //     const decades = ['1990s', '1980s', '1970s', '1960s', '1950s', '1940s', '1930s', '1920s'];
        //     setYears(['全部 All', ...yearsArray, ...decades]);
        // };

        fetchSeasons();
        fetchOccasions();
        fetchDynasties();
        // generatedYears();

    }, []);

    return (
        <div className="filter-container">
            {/* <div className="filter-section">
                <h6 className="filter-header">
                    <span>季节</span>
                    <span>Seasons</span>
                </h6>
                <div className="filter-selection">
                    {seasons.map((season) => (
                        <button
                        disabled
                            className={`btn btn-light btn-filter ${filters.season === (season.season_name_en === 'All' ? 'all' : season.season_name_en) ? 'btn-filter-active' : ''}`}
                            key={season.season_id} 
                            onClick={() => {
                            onFilterChange('season', season.season_name_en === 'All' ? 'all' : season.season_name_en);
                            onSelectionChange('season', {cn: season.season_name_cn, en: season.season_name_en});
                            }}>
                            {season.season_name_cn} {season.season_name_en}
                        </button>
                    ))}
                </div>
            </div> */}
            {/* <div className="filter-section">
                <h6 className="filter-header">
                    <span>场合</span>
                    <span>Occasions</span>
                </h6>
                <div className="filter-selection">
                    {occasions.map((occasion) => (
                        <button
                        disabled
                            className={`btn btn-light btn-filter ${filters.occasion === (occasion.occasion_name_en === 'All' ? 'all' : occasion.occasion_name_en) ? 'btn-filter-active' : ''}`}
                            key={occasion.occasion_id} 
                            onClick={() => {
                            onFilterChange('occasion', occasion.occasion_name_en === 'All' ? 'all' : occasion.occasion_name_en);
                            onSelectionChange('occasion', {cn: occasion.occasion_name_cn, en: occasion.occasion_name_en});
                            }}>
                            {occasion.occasion_name_cn} {occasion.occasion_name_en}
                        </button>
                    ))}
                </div>
            </div> */}
            {/* <div className="filter-section">
                <h6 className="filter-header">
                    <span>朝代</span>
                    <span>Dynasties</span>
                </h6>
                <div className="filter-selection">
                    {dynasties.map((dynasty) => (
                        <button
                        disabled
                            // className={`btn btn-light btn-filter ${filters.dynasty === dynasty.dynasty_name_en ? 'btn-filter-active' : ''}`}
                            className={`btn btn-light btn-filter ${filters.dynasty === (dynasty.dynasty_name_en === 'All' ? 'all' : dynasty.dynasty_name_en) ? 'btn-filter-active' : ''}`}
                            // key={dynasty.dynasty_id} onClick={() => onFilterChange('dynasty', dynasty.dynasty_name_en)}>
                            key={dynasty.dynasty_id} 
                            onClick={() => {
                            onFilterChange('dynasty', dynasty.dynasty_name_en === 'All' ? 'all' : dynasty.dynasty_name_en);
                            onSelectionChange('dynasty', {cn: dynasty.dynasty_name_cn, en: dynasty.dynasty_name_en});
                            }}>
                            {dynasty.dynasty_name_cn} {dynasty.dynasty_name_en}
                        </button>
                    ))}
                </div>

            </div> */}
            {/* <div className="filter-section">
                <h6 className="filter-header">
                    <span>年份</span>
                    <span>Years</span>
                </h6>
                <div className="filter-selection">
                    {years.map((year, index) => (
                        <button
                            className={`btn btn-light btn-filter ${filters.year === (year === '全部 All' ? 'all' : year) ? 'btn-filter-active' : ''}`}
                            // key={index} onClick={() => onFilterChange('year', year)}>
                            key={index} onClick={() => onFilterChange('year', year === '全部 All' ? 'all' : year)}>
                            {year}
                        </button>
                    ))}
                </div>
            </div> */}
        </div>
    )
}

export default ProductFilter; 