// src/context/UserContext.js

import React, { createContext, useState, useEffect, useMemo } from 'react';

// Create Context
const UserContext = createContext();

// Provide Context
export const UserProvider = ({ children }) => {
    // Initialize user state from local storage to persist login state across sessions
    const [user, setUser] = useState(() => {
        try {
            const storedUser = localStorage.getItem('user'); 
            return storedUser ? JSON.parse(storedUser) : null;
        } catch (error) {
            console.error('Failed to parse user from localStorage', error);
            return null; 
        }
    });

    useEffect(() => {
        // Persist user state to local storage whenever it changes
        if (user) {
            localStorage.setItem('user', JSON.stringify(user)); 
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    const loginUser = (userData) => {
        // Assuming to store both userId and username
        setUser(userData); 
        // Optionally, store them in local storage as well
        localStorage.setItem('user', JSON.stringify(userData));
    };

    // const loginUser = ({ token, userId, username }) => {
    //     // Assuming to store both userId and username
    //     setUser({ token, userId, username }); 
    //     // Optionally, store them in local storage as well
    //     localStorage.setItem('user', JSON.stringify({ token, userId, username }));
    // };

    // const loginUser = (userData) => {
    //     // Assuming userData includes userId, token, etc.
    //     setUser(userData);
    // };

    const logoutUser = () => {
        // Ensure to clear user from local storage on logout
        localStorage.removeItem('user'); 
        setUser(null);
    };

    const updateUser = (updates) => {
        setUser(prevUser => {
            const updatedUser = { ...prevUser, ...updates }; 
            localStorage.setItem('user', JSON.stringify(updatedUser)); 
            return updatedUser;
        });
    };

    const isTokenValid = () => {
        return !!user && !!user.token; 
    }; 

    const getToken = () => {
        return user ? user.token : null; 
    }; 

    // Provide all context value as an object
    const contextValue = useMemo(() => {
       return { user, setUser, loginUser, logoutUser, updateUser, isTokenValid, getToken };
        // Depend on user object, ensuring it only recalculates if user changes
    }, [user]); 

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
}

// Export the context itself for useContext hook in consumer components
export default UserContext;
