// src/components/watch/TitleProducts.js 

import React from "react";
import ProductCard from "../shop/ProductCard";
import { entityProductsData } from "../shop/EntityProductsData";
import '../../css/TitleGrid.css';

const TitleProducts = ({ entityId, entityType = "title" }) => {
    const products = entityProductsData.filter(
        product => product.entity_id === entityId && product.entity_type === entityType
    );

    return (
        <div className="title-products-section">
            {products.length > 0 ? (
                <div className="products-grid">
                    {products.map((product) => (
                        <ProductCard
                            key={product.id}
                            product={product}
                        />
                    ))}
                </div>
            ) : (
                <p className="">Coming soon...</p>
            )
            }
        </div>
    );
};

export default TitleProducts;