// src/components/user/UserGeneratedImages.js; 

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../css/Generate.css';
import '../../css/UserProfile.css';

const UserGeneratedImages = () => {

    const { username } = useParams();
    const isPaidUser = true;

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
    // const USER_IMAGES_API_URL = `${API_BASE_URL}/api/users/${userId}/images` || `http://localhost:3000/api/users/${userId}/images`;
    const USER_IMAGES_API_URL = `${API_BASE_URL}/api/users/${username}/images` || `http://localhost:3000/api/users/${username}/images`;

    console.log(USER_IMAGES_API_URL);

    useEffect(() => {
        if (isPaidUser) {
            fetch(USER_IMAGES_API_URL)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setImages(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching images:', error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
        // }, [userId, isPaidUser]);
    }, [username, isPaidUser]);


    function handleImageClick(event) {
        event.preventDefault();
        console.log("Image was clicked but did not navigate.")
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        console.log("Right-click disabled on this image.")
    }

    return (
        <div>
            <h5>我的AI生成图像 🥰</h5>
            <h6>My AI Generated Images 🥰</h6>
            <div className="images-container">
                {loading ? (
                    <p>Loading...</p>
                ) : isPaidUser ? (
                    <div className="image-grid">
                        {images.map((image) => (
                            <div key={image.ai_image_id} className="gallery-item">
                                <img className="gallery-image"
                                    src={image.generated_image_url}
                                    onClick={handleImageClick}
                                    onContextMenu={handleContextMenu}
                                    alt="generated" />
                                <div className="gallery-image-info">
                                    <span>{new Date(image.created_at).toLocaleDateString()}</span>
                                </div>
                            </div>
                        ))}
                    </div>) : (
                    <p>Upgrade your account to Lover to see all of the images you generated!  </p>
                )}
            </div>
        </div>

    )
}

export default UserGeneratedImages; 