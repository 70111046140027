// src/pages/TitlesPage.js
import React, { useState, useEffect } from 'react';
import TitleFilter from './TitleFilter';
import TitleSort from './TitleSort';
import TitleGrid from './TitleGrid';
import '../../css/Page.css';

// type prop will be 'cdrama', 'cmovie', 'manhua', 'donghua', 'cvariety', etc.
const TitlesPage = ({ type, typeName }) => {

    const [titles, setTitles] = useState([]);
    const [filters, setFilters] = useState({ genre: 'all', dynasty: 'all', year: 'all' });
    const [sortBy, setSortBy] = useState('aired');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({
        genre: { cn: '全部', en: 'All' },
        dynasty: { cn: "全部", en: 'All' },
        year: 'all',
        sortBy: { cn: '播出', en: 'Aired' }
    });
    const [selectedSummary, setSelectedSummary] = useState('');

    useEffect(() => {
        // Call this function to update the summary based on the current state
        updateSelectedSummary();

        const fetchTitles = async () => {
            setLoading(true);
            try {
                // Define the query parameters
                let queryParams = new URLSearchParams({
                    ...filters,
                    sortBy,
                    // Add the type to query parameters 
                    type
                }).toString();

                // Adjust the query parameters to exclude 'all' filters
                queryParams = queryParams.split('&').filter(q => !q.includes('all')).join('&');

                // Construct the API URL with query parameters
                const TITLES_QUERY_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/watch/titles?${queryParams}` || `http://localhost:3000/api/watch/titles?${queryParams}`;
                // console.log(TITLES_QUERY_API_URL)

                const response = await fetch(TITLES_QUERY_API_URL);
                if (!response.ok) {
                    throw new Error('Oh no, there is no result for your filtered search, but we are constantly adding new titles, please check back! :)');
                }
                const data = await response.json();
                setTitles(data);
                setError(null); // Reset error state on successful fetch
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTitles();
        // Include 'type' in the dependency array    
    }, [filters, sortBy, type]);

    const handleFilterChange = (filterCategory, value) => {
        // setFilters(prev => ({ ...prev, [filterCategory]: value }));
        const backendValue = value === 'All' ? 'all' : value;
        setFilters(prev => ({ ...prev, [filterCategory]: backendValue }));
    };

    const handleSelectionChange = (category, { cn, en }) => {
        setSelectedOptions(prev => ({ ...prev, [category]: { cn, en } }));
    };

    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
    };
    // console.log(sortBy);

    // const updateSelectedSummary = () => {
    //     // Construct the summary string based on selected filters and sorting
    //     const genreText = filters.genre === 'all' ? 'All' : filters.genre; 
    //     const dynastyText = filters.dynasty === 'all' ? 'All': filters.dynasty; 
    //     const yearText = filters.year === 'all' ? 'All' : filters.year; 
    //     const sortByText = sortBy.charAt(0).toUpperCase() + sortBy.slice(1); // Capitalize the first letter  
    //     const summary = `Showing results for Genre: ${genreText} | Dynasty: ${dynastyText} | Year: ${yearText} | Sort by: ${sortByText}`; 
    //     setSelectedSummary(summary);
    // }

    /// Selected summary with Chinese name too: 
    const updateSelectedSummary = () => {
        const { genre, dynasty } = selectedOptions;
        const yearText = filters.year === 'all' ? '全部 All' : filters.year;
        const sortByTextEn = sortBy.charAt(0).toUpperCase() + sortBy.slice(1);
        // const sortByTextCn = getChineseNameForSortOption(sortBy); 
        const summary = `展示结果 Showing results：种类 Type: ${typeName} | 类别 Genre: ${genre.cn} ${genre.en} | 朝代 Dynasty: ${dynasty.cn} ${dynasty.en} | 年份 Year: ${yearText}`;
        // const summary = `展示结果 Showing results: 年份 Year: ${yearText} |  排序 Sorted by: ${sortByTextCn} ${sortByTextEn}`;
        // const summary = `展示结果 Showing results: 种类 Type: ${typeName} | 年份 Year: ${yearText} |  排序 Sorted by: ${sortByTextEn}`;
        // 排序 Sorted by: ${sortByTextCn} ${sortByTextEn}
        setSelectedSummary(summary);
    }

    return (
        <div className="page-wrapper">
            <div className="filter-section-text">
                <h6>按类别和朝代查找即将推出！请暂时先按年份过滤或排序查找 ✨</h6>
                <h6>Filter by Genres and Dynasties coming soon! Meanwhile, please filter by Years or Sort by ✨</h6>
            </div>
            <div className="filter-sort-container">
                <TitleFilter
                    onFilterChange={handleFilterChange}
                    onSelectionChange={handleSelectionChange}
                    filters={filters}
                />
                <TitleSort onSortChange={handleSortChange} currentSort={sortBy} />
            </div>
            <div className="selected-summary">{selectedSummary}</div>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {!loading && !error && <TitleGrid titles={titles} />}
        </div>
    )
}

export default TitlesPage; 