import React from 'react'; 

const GetTokens = () => {
    return (
        <div>
            Coming soon... 
        </div>
    )
}

export default GetTokens; 