import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Checkout from './Checkout';

// Initialize Stripe.js with your public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

console.log("Current Environment:", process.env.NODE_ENV);
// console.log("Stripe API Key:", process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutPageWrapper = () => (
    <Elements stripe={stripePromise}>
        <Checkout />
    </Elements>
);

export default CheckoutPageWrapper;
