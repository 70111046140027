// src/components/TitleGrid.js; 
import React from 'react';
import TitleCard from './TitleCard';
import '../../css/TitleGrid.css';


const TitleGrid = ({ titles }) => {
    // Placeholder for actual title cards rendering

    return (
        <div className="titles-grid">
            {titles.map((title, index) => (
                <TitleCard key={title.title_id} title={title} />
            ))}
        </div>
    )
}

export default TitleGrid;