import React, { useState } from 'react';
import '../../css/Contact.css';

function ContactForm() {

    // Setting API base URL
    const CONTACT_API_URL = process.env.REACT_APP_API_BASE_URL + "/api/send" || 'http://localhost:3000/api/send';

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        type: '',
        subject: '',
        message: ''
    });

    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = event => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Update with backend URL:
            const response = await fetch(CONTACT_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setResponseMessage('Thank you for contacting us! We will get back to you soon.');
                setFormData({ name: '', email: '', type: '', subject: '', message: '' });
            } else {
                setResponseMessage('Message was not sent. Please try again.')
            }
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Message was not sent. Please try again.')
        }
    };

    return (
        <div className="contact-container">
            <h5>联系我们</h5>
            <h5>Contact Us 💌</h5>
            <form className="contact-form" id="contact-form" onSubmit={handleSubmit} >
                <div className="form-group mb-3">
                    <label htmlFor="name" className="form-label">姓名 Name (必须 required)</label>
                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">电子邮件 Email (必须 required)</label>
                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="type" className="form-label">咨询类型 Inquiry Type (必须 required)</label>
                    <select className="form-control" id="type" name="type" value={formData.type} onChange={handleChange} required>
                        <option value="">请选择 Please Choose One</option>
                        <option value="Business Partnership">商业合作 Business Opportunity</option>
                        <option value="Feature requests">报告问题 Report Issue</option>
                        <option value="Feature requests">功能需求 Feature Request</option>
                        <option value="Product Feedback">产品反馈 Product Feedback</option>
                        <option value="General Inquiry">一般问题 General Inquiry</option>
                        <option value="Other">其他 Other</option>
                    </select>
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="subject" className="form-label">主题 Subject</label>
                    <input type="text" className="form-control" id="subject" name="subject" value={formData.subject} onChange={handleChange} />
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="message" className="form-label">留言 Message (必须 required)</label>
                    <textarea id="message" className="form-control" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
                </div>

                <button type="submit" className="btn btn-light btn-contact">发送 Send 🚀</button>

                {responseMessage && <div>{responseMessage}</div>}

            </form>
        </div>
    )
}

export default ContactForm; 