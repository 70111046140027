// src/shop/EntityProductsData.js

export const entityProductsData = [
    {
        id: 1, 
        entity_id: 112, 
        entity_type: "title", 
        // product_id: "", 
        product_title_cn: "魔道祖师蓝忘机角色扮演套装", 
        product_title_en: "Grandmaster of Demonic Cultivation Cosplay Lan Wangji Costume", 
        product_description_cn: "", 
        product_description_en: "", 
        product_image_url:
        "https://m.media-amazon.com/images/I/51p9aMPR5+L._AC_SX679_.jpg", 
        product_dynasty_cn: "", 
        product_dynasty_en: "", 
        product_price_usd: "59.99", 
        product_affiliate_url: "https://amzn.to/4dHjq6D", 
        product_link: 
        "https://www.amazon.com/Mordarli-Grandmaster-Demonic-Cultivation-Cosplay/dp/B085LLX3NT/", 
        product_vendor: "Amazon", 
        featured: true, 
        relevance_score: 0.9, 
    }, 

    {
        id: 2, 
        entity_id: 112, 
        entity_type: "title", 
        // product_id: "", 
        product_title_cn: "魔道祖师魏无羡角色扮演套装", 
        product_title_en: "Grandmaster of Demonic Cultivation Cosplay Wei Wuxian Costume", 
        product_description_cn: "", 
        product_description_en: "", 
        product_image_url:
        "https://m.media-amazon.com/images/I/51dNl3m7PmL._AC_SY879_.jpg", 
        product_dynasty_cn: "", 
        product_dynasty_en: "", 
        product_price_usd: "69.99", 
        product_affiliate_url: "https://amzn.to/4e9jKuF", 
        product_link: 
        "https://www.amazon.com/Mordarli-Grandmaster-Demonic-Cultivation-Cosplay/dp/B07QWF2KQ3/", 
        product_vendor: "Amazon", 
        featured: true, 
        relevance_score: 0.9, 
    }, 

    {
        id: 3, 
        entity_id: 112, 
        entity_type: "title", 
        // product_id: "", 
        product_title_cn: "魔道祖师江澄角色扮演套装", 
        product_title_en: "Grandmaster of Demonic Cultivation Cosplay Jiang Cheng Costume", 
        product_description_cn: "", 
        product_description_en: "", 
        product_image_url:
        "https://m.media-amazon.com/images/I/51UieV5IKdL._AC_SX679_.jpg", 
        product_dynasty_cn: "", 
        product_dynasty_en: "", 
        product_price_usd: "59.99", 
        product_affiliate_url: "https://amzn.to/3XqSBxE", 
        product_link: 
        "https://www.amazon.com/Mordarli-Grandmaster-Demonic-Cultivation-Cosplay/dp/B0CQCHF6M4/", 
        product_vendor: "Amazon", 
        featured: true, 
        relevance_score: 0.9, 
    }, 

    {
        id: 4, 
        entity_id: 112, 
        entity_type: "title", 
        // product_id: "", 
        product_title_cn: "魔道祖师金凌角色扮演套装", 
        product_title_en: "Grandmaster of Demonic Cultivation Cosplay Jin Ling Costume", 
        product_description_cn: "", 
        product_description_en: "", 
        product_image_url:
        "https://m.media-amazon.com/images/I/41EOrqqKlkL._AC_SX679_.jpg", 
        product_dynasty_cn: "", 
        product_dynasty_en: "", 
        product_price_usd: "59.99", 
        product_affiliate_url: "https://amzn.to/3T7K4NK", 
        product_link: 
        "https://www.amazon.com/Mordarli-Grandmaster-Demonic-Cultivation-Cosplay/dp/B0CQC4BMBX/", 
        product_vendor: "Amazon", 
        featured: true, 
        relevance_score: 0.9, 
    }, 
    
    {
        id: 5, 
        entity_id: 112, 
        entity_type: "title", 
        // product_id: "", 
        product_title_cn: "魔道祖师年轻魏无羡角色扮演套装", 
        product_title_en: "Grandmaster of Demonic Cultivation Cosplay Young Wei Wuxian Costume", 
        product_description_cn: "", 
        product_description_en: "", 
        product_image_url:
        "https://m.media-amazon.com/images/I/51qN8FRSOEL._AC_SX679_.jpg", 
        product_dynasty_cn: "", 
        product_dynasty_en: "", 
        product_price_usd: "59.99", 
        product_affiliate_url: "https://amzn.to/4dK7wsr", 
        product_link: 
        "https://www.amazon.com/Mordarli-Grandmaster-Demonic-Cultivation-Cosplay/dp/B0CQC4DHGG/", 
        product_vendor: "Amazon", 
        featured: true, 
        relevance_score: 0.9, 
    }, 

    {
        id: 6, 
        entity_id: 112, 
        entity_type: "title", 
        // product_id: "", 
        product_title_cn: "魔道祖师温宁角色扮演套装", 
        product_title_en: "Grandmaster of Demonic Cultivation Cosplay Wen Ning Costume", 
        product_description_cn: "", 
        product_description_en: "", 
        product_image_url:
        "https://m.media-amazon.com/images/I/51kiHLKpaHL._AC_SX679_.jpg", 
        product_dynasty_cn: "", 
        product_dynasty_en: "", 
        product_price_usd: "59.99", 
        product_affiliate_url: "https://amzn.to/3XbhvQJ", 
        product_link: 
        "https://www.amazon.com/Mordarli-Grandmaster-Demonic-Cultivation-Cosplay/dp/B0CQC6ND2B/", 
        product_vendor: "Amazon", 
        featured: true, 
        relevance_score: 0.9, 
    }, 

    {
        id: 7, 
        entity_id: 112, 
        entity_type: "title", 
        // product_id: "", 
        product_title_cn: "魔道祖师温情角色扮演套装", 
        product_title_en: "Grandmaster of Demonic Cultivation Cosplay Wen Qing Costume", 
        product_description_cn: "", 
        product_description_en: "", 
        product_image_url:
        "https://m.media-amazon.com/images/I/51xFM0xwL7L._AC_SX679_.jpg", 
        product_dynasty_cn: "", 
        product_dynasty_en: "", 
        product_price_usd: "59.99", 
        product_affiliate_url: "https://amzn.to/4e2BI1K", 
        product_link: 
        "https://www.amazon.com/Mordarli-Grandmaster-Demonic-Cultivation-Cosplay/dp/B0CQC2VJ3Y", 
        product_vendor: "Amazon", 
        featured: true, 
        relevance_score: 0.9, 
    }, 
]