import React from 'react';
import ContactForm from '../about/ContactForm';
import '../../css/Contact.css';

function Report() {
    return (
        <div className="page-wrapper">
            <div className="report-header">
                <h5>请选择 "报告问题 Report Issue"，然后添加无效的 URL 链接。谢谢！🥰 </h5>
                <h5>Please select "报告问题 Report Issue", and add the URL link that is not working. Thank you 🥰</h5>
            </div>
            <div className="report-container">
                <h5>报告无效链接</h5>
                <h5>Report Link Not Working</h5>
                <ContactForm />
            </div>

        </div>

    )
}

export default Report; 