// src/components/create/Gallery.js

import React, { useEffect, useState } from 'react';
import '../../css/Generate.css';

const Gallery = () => {
    const [images, setImages] = useState([]);

    const IMAGES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/create/gallery` || 'http://localhost:3000/api/create/gallery';

    // console.log(IMAGES_API_URL);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch(IMAGES_API_URL);
                const data = await response.json();
                if (response.ok) {
                    setImages(data.images);
                } else {
                    console.error('Failed to fetch images', data.message);
                }
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [IMAGES_API_URL])

    function handleImageClick(event) {
        event.preventDefault();
        console.log("Image was clicked but did not navigate.")
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        console.log("Right-click disabled on this image.")
    }

    return (
        <div className="page-wrapper">
            <h5>社区图片画廊</h5>
            <h5>Community Image Gallery 🖼️</h5>
            <div className="gallery-container">
                <div className="gallery-grid">
                    {images.map((image) => (
                        <div key={image.ai_image_id} className="gallery-item">
                            <img className="gallery-image"
                                src={image.generated_image_url}
                                onClick={handleImageClick}
                                onContextMenu={handleContextMenu}
                                alt="generated" />
                            <div className="gallery-image-info">
                                {/* <span>{new Date(image.created_at).toLocaleDateString()}</span> */}
                                {/* <span>{image.generated_image_url}</span> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Gallery; 