import React, { useState, useMemo } from 'react';
import events from './EventsData';
import EventList from './EventList';

const parseDateTime = (dateString, timeString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
};

const Events = () => {
    const [activeTab, setActiveTab] = useState('upcoming');

    const { sortedEvents, upcomingEvents, pastEvents } = useMemo(() => {
        const now = new Date();
        const sorted = events.sort((a, b) => {
            const aStart = parseDateTime(a.event_start_date, a.event_start_time);
            const bStart = parseDateTime(b.event_start_date, b.event_start_time);
            const aEnd = parseDateTime(a.event_end_date, a.event_end_time);
            const bEnd = parseDateTime(b.event_end_date, b.event_end_time);

            if (aStart <= now && bStart <= now) {
                return bEnd - aEnd;
            } else if (aStart <= now) {
                return -1;
            } else if (bStart <= now) {
                return 1;
            } else {
                return aStart - bStart;
            }
        });

        const upcoming = sorted.filter(e => parseDateTime(e.event_end_date, e.event_end_time) >= now); 
        const past = sorted.filter(e => parseDateTime(e.event_end_date, e.event_end_time) < now); 

        return { sortedEvents: sorted, upcomingEvents: upcoming, pastEvents: past }; 
    }, []);

    const filteredEvents = useMemo(() => {
        if (activeTab === 'all') {
            return sortedEvents.sort((a, b) => {
                const now = new Date(); 
                const aStart = parseDateTime(a.event_start_date, a.event_start_time); 
                const bStart = parseDateTime(b.event_start_date, b.event_start_time); 
                const aEnd = parseDateTime(a.event_end_date, a.event_end_time); 
                const bEnd = parseDateTime(b.event_end_date, b.event_end_time); 

                // Ongoing events first 
                if (aStart <= now && aEnd > now) {
                    if (bStart <= now && bEnd > now) {
                        return aEnd - bEnd; // Both ongoing, sort by end time
                    }
                    return -1; // A is ongoing, B is not 
                }

                if (bStart <= now && bEnd > now) {
                    return 1; // B is ongoing, A is not 
                }

                // Then upcoming events 
                if (aStart > now && bStart > now) {
                    return aStart - bStart; 
                }

                if (aStart > now) return -1; 
                if (bStart > now) return 1; 
                
                // Finally past events
                return bEnd - aEnd; // Sort past events by end time, most recent first
            }); 
        }

    //     return sortedEvents.filter(e => {
    //         const eventEnd = parseDateTime(e.event_end_date, e.event_end_time);
    //         return activeTab === 'upcoming' ? eventEnd >= now : eventEnd < now;
    //     });
    // }, [activeTab, sortedEvents]);

    return activeTab === 'upcoming' ? upcomingEvents : pastEvents; 
}, [activeTab, sortedEvents, upcomingEvents, pastEvents]); 

const renderEventlist = () => {
    if (filteredEvents.length === 0) {
        if (activeTab === 'upcoming') {
            return <div>More events are coming soon. Stay tuned! ❤️</div>; 
        } else if (activeTab === 'past') {
            return <div> There are no past events yet.</div>; 
        } else {
            return <div>More events are coming soon. Stay tuned! ❤️</div>
        }
    }
    return <EventList events={filteredEvents} />; 
}


    // console.log('All Events:', events);
    // console.log('Sorted Events:', sortedEvents);
    // console.log('Filtered Events:', filteredEvents);

    return (
        <div className="page-wrapper">
            <h5>活动 🥳</h5>
            <h5>Events 🥳</h5>
            <div className="event-tabs-container">
                <div className="event-tabs">
                    <button
                    className={activeTab === 'all' ? 'active' : ''}
                    onClick={() => setActiveTab('all')}
                    >
                        所有 All ({sortedEvents.length})
                    </button>
                    <button
                        className={activeTab === 'upcoming' ? 'active' : ''}
                        onClick={() => setActiveTab('upcoming')}
                    >
                       即将举行 Upcoming ({upcomingEvents.length})
                    </button>
                    <button
                        className={activeTab === 'past' ? 'active' : ''}
                        onClick={() => setActiveTab('past')}
                    >
                        过去 Past ({pastEvents.length})
                    </button>
                </div>
                <div className="events-container">
                   {renderEventlist()}
                </div>
            </div>
        </div>
    )
}

export default Events;