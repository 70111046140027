// src/components/watch/SearchTitleBar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../../css/Search.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchTitleResults() {
    const query = useQuery();
    const searchQuery = query.get('q');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const TITLESEARCH_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/watch/search/titles?q=${encodeURIComponent(searchQuery)}` || `http://localhost:3000/api/watch/search/titles?q=${encodeURIComponent(searchQuery)}`

    useEffect(() => {
        if (searchQuery) {
            fetch(TITLESEARCH_API_URL)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    // setResults(data); 
                    // Ensure data is always treated as an array, if not, convert it to an array with one element
                    setResults(Array.isArray(data) ? data : [data])
                    setLoading(false);
                })
                .catch(err => {
                    setError(err.toString());
                    setLoading(false);
                });
        }
    }, [searchQuery]);

    if (loading) return <div>加载中 Loading...</div>
    if (error) return <div>加载结果出错 Erorr loading results. 🙁</div>

    console.log(results);

    return (
        <div className="page-wrapper search-results">
            <h5 className="page-header">Search Results for "<span className="search-term">{searchQuery}</span>"</h5>
            <div className="results-container">
                {results.map((item, index) => (
                    <Link to={`/watch/titles/${item.title_id}`} className="title-item-link">
                        <div className="result-item" key={index}>
                            <div className="result-item-left">
                                <img className="result-title-image" src={item.title_image_url} alt={item.title_name_en} />
                            </div>
                            <div className="result-item-right">
                                <div className="result-item-details">
                                    {/* <span className="result-item-label">片名:</span> */}
                                    <span className="result-item-title">{item.title_name_cn} ({item.title_year})</span>
                                </div>
                                <div className="result-item-details">
                                    {/* <span className="result-item-label">Title:</span> */}
                                    <span className="result-item-title">{item.title_name_en} ({item.title_year})</span>
                                </div>
                                <div className="result-item-details">
                                    <span className="result-item-label">简介 Description:</span>
                                    <span className="result-item-text"> {item.title_description_cn}</span>
                                    <span className="result-item-text"> {item.title_description_en ? item.title_description_en : ""}</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default SearchTitleResults; 