// src/components/watch/SearchTitleDropdown.js
import React from 'react'; 
import '../../css/AppNavbar.css';

const SearchTitleDropdown = ({ results, onSelect }) => {
    return (
        <div className="search-dropdown">
            {results.map((item, index) => (
                <div key={index} className="search-dropdown-item" onClick={() => onSelect(item)}>
                    <img src={item.title_image_url} alt={item.title_name_en} className="search-dropdown-image" />
                    <div className="search-dropdown-details">
                        <div>{item.title_name_cn} ({item.title_year})</div>
                        <div>{item.title_name_en} ({item.title_year})</div>
                    </div>    
                </div>
            ))}
        </div>
    )
}

export default SearchTitleDropdown; 