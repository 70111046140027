import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import '../../css/UserProfile.css';

const UserAccountForm = () => {
    const { user, setUser } = useContext(UserContext);
    const [username, setUsername] = useState(user?.username || '');
    const [email, setEmail] = useState(user?.email || '');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const BACKEND_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleUpdate = async (field, value) => {
        setLoading(true);
        setResponseMessage('');
        try {
            const USER_ACCOUNT_API_URL = `${BACKEND_BASE_URL}/api/users/${user.username}/${field}`;
            // console.log(USER_ACCOUNT_API_URL);
            const response = await fetch(USER_ACCOUNT_API_URL, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify({ [`new${field.charAt(0).toUpperCase() + field.slice(1)}`]: value }),
            });

            const data = await response.json();

            if (response.ok) {
                setResponseMessage(`${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully.`);
                // Update the user context 
                setUser(prevUser => ({
                    ...prevUser,
                    [field]: value
                }));

                if (field === 'username') {
                    navigate(`/users/${value}/account`);
                }
            } else {
                setResponseMessage(data.message || `Failed to update ${field}.`);
            }
        } catch (error) {
            console.error(`Error updating ${field}:`, error);
            setResponseMessage(`An error occurred while updating ${field}.`);
        }
        setLoading(false);
    };

    const handleUpdateUsername = (e) => {
        e.preventDefault();
        if (username !== user.username) {
            handleUpdate('username', username);
        }
    };

    const handleUpdateEmail = (e) => {
        e.preventDefault();
        if (email !== user.email) {
            handleUpdate('email', email);
        }
        // console.log('New email:', email);
    };

    const handleUpdatePassword = (e) => {
        e.preventDefault();
        if (newPassword) {
            handleUpdate('password', newPassword);
        }
    };

    return (
        <div className="account-form-wrapper">
            <h5>我的账号 🏡</h5>
            <h5>My Account 🏡</h5>
            <div className="account-form-container">
                {responseMessage && <p className="response-message">{responseMessage}</p>}
                {/* Form for updating username */}
                <form className="account-form username-form" onSubmit={handleUpdateUsername}>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label className="form-label" htmlFor="username">
                                用户名 Username:
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-account" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>

                {/* Form for updating email */}
                <form className="account-form email-form" onSubmit={handleUpdateEmail}>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label className="form-label" htmlFor="email">
                                电子邮件 Email:
                            </label>
                            <input
                                className="form-control"
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-account" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>
                </form>

                {/* Form for updating password */}
                <form className="account-form password-form" onSubmit={handleUpdatePassword}>
                    <div className="form-group-container">
                        <div className="form-group password-group">
                            <label className="form-label" htmlFor="currentPassword">
                                现密码 Current Password:
                            </label>
                            <div className="password-container">
                                <input
                                    className="form-control"
                                    type={showCurrentPassword ? "text" : "password"}
                                    id="currentPassword"
                                    value={currentPassword}
                                    placeholder="********"
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                                <i className="form-password-icon" onClick={toggleCurrentPasswordVisibility}>
                                    {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                                </i>
                            </div>
                        </div>
                        <div className="form-group password-group">
                            <label className="form-label" htmlFor="newPassword">
                                新密码 New Password:
                            </label>
                            <input
                                className="form-control"
                                type={showNewPassword ? "text" : "password"}
                                id="newPassword"
                                value={newPassword}
                                placeholder="********"
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <i className="form-password-icon" onClick={toggleNewPasswordVisibility}>
                                {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                            </i>
                        </div>
                        <button type="submit" className="btn btn-light btn-account" disabled={loading}>
                            {loading ? '更新中 Updating...❤️' : '更新 Update'}
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default UserAccountForm;
