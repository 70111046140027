// src/pages/UserPage.js; 

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import UserProfileForm from './UserProfileForm';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';
import UserGeneratedImages from './UserGeneratedImages';
import "../../css/UserProfile.css";

const UserPage = () => {
    // Using React Router and the route: "/users/:userId"
    // const { userId } = useParams();
    const { username } = useParams();
    const { user: currentUser } = useContext(UserContext);
    const [userData, setUserData] = useState(null);
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState('');
    // const [hasProfile, setHasProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // console.log("Current user from context:", currentUser);

    // const USER_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}` || `http://localhost:3000/api/users/${userId}`;
    // const USER_PROFILE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/profile` || `http://localhost:3000/api/users/${userId}/profile`;

    const USER_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/users/${username}` || `http://localhost:3000/api/users/${username}`;
    const USER_PROFILE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/users/${username}/profile` || `http://localhost:3000/api/users/${username}/profile`;

    const fetchProfile = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(USER_PROFILE_API_URL);
            if (!response.ok) {
                throw new Error('Failed to fetch profile');
            }
            const data = await response.json();
            setProfile(data);
            setIsLoading(false);
            console.log(data);
        } catch (err) {
            setError(err.message || 'An error occured while fetching the profile.');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(USER_API_URL);
                if (!response.ok) {
                    throw new Error('Failed to fetch profile');
                }
                const data = await response.json();
                setUserData(data);
                setIsLoading(false);
                console.log(data);
            } catch (err) {
                setError(err.message || 'An error occured while fetching the user.');
                setIsLoading(false);
            }
        };

        fetchUser();
        fetchProfile();
        // }, [userId, currentUser]);
    }, [username, currentUser]);

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>{error}</div>
    }

    // Since there are already error handling and a loading state, this check is technically redundant,
    // but it's a good practice to ensure the object exists before accessing its properties.
    const hasProfile = profile && (profile.isPublic || (currentUser && profile.user_id === currentUser.userId));

    // console.log(profile);
    // console.log(userData);

    return (
        <div className="page-wrapper">
            <UserProfileForm username={currentUser.username} />
            {/* <div className="profile-main">
                    {(userData?.user_role === "admin") ?
                        (<AdminDashboard userId={currentUser?.userId} />)
                        :
                        (<UserDashboard userId={currentUser?.userId} />)
                    }
                </div> */}

        </div>
    )
};
export default UserPage;

