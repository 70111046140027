// src/components/watch/SearchTitleBar.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaTimes } from "react-icons/fa";
import SearchTitleDropdown from './SearchTitleDropdown';
import '../../css/AppNavbar.css';

const SearchTitleBar = () => {
    const [query, setQuery] = useState('');
    const [dropdownResults, setDropdownResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    // const handleSearch = async (e) => {
    //     e.preventDefault();
    //     navigate(`/watch/search/titles?q=${encodeURIComponent(query)}`);
    // };

    const handleSearch = async (e) => {
        e.preventDefault();
        setShowDropdown(false);
        navigate(`/watch/search/titles?q=${encodeURIComponent(query)}`);
    };

    const TITLESEARCH_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/watch/search/titles` || `http://localhost:3000/api/watch/search/titles`

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (query.length > 0) {
            fetch(`${TITLESEARCH_API_URL}?q=${encodeURIComponent(query)}`)
                .then(response => response.json())
                .then(data => {
                    // setDropdownResults(Array.isArray(data) ? data.slice(0, 5) : [data].slice(0, 5));
                    setDropdownResults(Array.isArray(data) ? data : [data]);
                    setShowDropdown(true);
                })
                .catch(error => console.error('Error fetching dropdown results:', error));
        } else {
            setDropdownResults([]);
            setShowDropdown(false);
        }
    }, [query]);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    // const clearSearch = () => {
    //     setQuery(''); 
    //     setDropdownResults([]); 
    //     setShowDropdown(false); 
    // };

    const handleClearInput = () => {
        setQuery(''); 
        setDropdownResults([]); 
        setShowDropdown(false); 
    }; 

    return (
        <div className="searchbar-container" ref={dropdownRef}>
            <form className="searchbar-form" onSubmit={handleSearch}>
                {/* {query.length > 0 && (
                    <FaTimes className="clear-icon" onClick={clearSearch} />
                )} */}
                {query && (
                    <button 
                    type="button"
                    className="btn-clear"
                    onClick={handleClearInput}>
                        <FaTimes className="clear-icon" />
                    </button>
                )}
                <input
                    className="search-input"
                    type="text"
                    placeholder="搜索片名 Search for titles..."
                    value={query}
                    onChange={handleInputChange}
                />
                <button className="btn-searchbar" type="submit"><FaSearch className="search-icon" /></button>
            </form>
            {showDropdown && 
                <SearchTitleDropdown 
                    results={dropdownResults} 
                    onSelect={(item) => {
                        setQuery(item.title_name_en);
                        setShowDropdown(false);
                        navigate(`/watch/titles/${item.title_id}`);
                    }} 
                />}
        </div>
    )
};

export default SearchTitleBar; 
