import React from 'react';
import { FaRegHeart, FaHeart, FaThumbsUp, FaThumbsDown, FaRegComment,FaComment, FaRegEye, FaEye, FaRegBookmark, FaBookmark, FaShare } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Post = ({ post }) => {

    return (
            <tr className="post-row">
                <td>
                    <h6>{post.post_title}</h6>
                    <span>{post.post_content}</span>
                </td>
                <td>{post.user_id}</td>
                <td><FaRegHeart /> {post.post_likes_count}</td>
                <td><FaRegComment /> {post.post_comments_count}</td>
                <td><FaRegEye />{post.post_views_count}</td>
                {/* <td><FaRegBookmark />{post.post_saves_count}</td> */}
            </tr>
    )
}

export default Post; 