// src/pages/TitlePage.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import UserContext from '../../context/UserContext';
import StarRating from './StarRating';
import Watchlinks from './Watchlinks';
import TitleProducts from './TitleProducts';
import { FaHeart, FaRegHeart, FaBookmark, FaRegBookmark } from 'react-icons/fa'; // Ensure you have these icons
import { PiTelevisionSimpleBold, PiTelevisionSimpleFill, PiPlayBold, PiPlayFill } from "react-icons/pi";
import { BsPlayBtn, BsPlayBtnFill } from "react-icons/bs";
import { RiShoppingBagLine, RiShoppingBagFill } from "react-icons/ri";
// import ReactPlayer from 'react-player/youtube'

import TitleReviewForm from './TitleReviewForm';
import TitleReviews from './TitleReviews';
import '../../css/Page.css';

function TitlePage() {
    const { titleId } = useParams();
    const { user } = useContext(UserContext);
    const navigate = useNavigate(); // Instantiate useNavigate

    const [titleDetails, setTitleDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [averageRating, setAverageRating] = useState(0);
    const [ratingsCount, setRatingsCount] = useState(0);
    const [userRating, setUserRating] = useState(0);

    const [isFavorite, setIsFavorite] = useState(false);
    const [isWatchlisted, setIsWatchlisted] = useState(false);
    const [activeTab, setActiveTab] = useState('trailer'); // default active tab

    // Define the API endpoint. Adjust it according to your backend setup.

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

    const TITLE_API_URL = `${API_BASE_URL}/api/watch/titles/${titleId}`;
    const INCREMENT_API_URL = `${API_BASE_URL}/api/watch/titles/${titleId}/increment-view`;

    const RATINGS_API_URL = `${API_BASE_URL}/api/ratings`;
    const TITLE_RATING_API_URL = `${API_BASE_URL}/api/ratings/title/${titleId}`;
    const USER_RATING_API_URL = `${API_BASE_URL}/api/ratings/title/${titleId}/${user?.userId}`;

    const LIKE_API_URL = `${API_BASE_URL}/api/likes`;
    const SAVE_API_URL = `${API_BASE_URL}/api/saves`;

    // Fetch title details: 
    const fetchTitleDetails = useCallback(async () => {
        try {
            const response = await fetch(TITLE_API_URL);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setTitleDetails(data);
            setAverageRating(Number(data.title_average_rating) || 0);
            setRatingsCount(Number(data.title_ratings_count) || 0);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching title details:', error);
            setError(error);
            setLoading(false);
        }
    }, [TITLE_API_URL]);

    // Fetch user rating: 
    const fetchUserRating = useCallback(async () => {
        if (user?.userId) {
            try {
                const response = await fetch(USER_RATING_API_URL);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setUserRating(Number(data.rating_score) || 0);
            } catch (error) {
                console.error('Error fetching user rating:', error);
                // Set userRating to 0 if there's an error
                setUserRating(0);
            }
        }
    }, [USER_RATING_API_URL, user]);

    // Increment title views count
    useEffect(() => {
        fetchTitleDetails();
        fetchUserRating();

        fetch(INCREMENT_API_URL, {
            method: 'POST'
        })
            .catch(error => console.error('Error incrementing view count:', error));
    }, [fetchTitleDetails, fetchUserRating, INCREMENT_API_URL]);


    // handle submit rating
    const handleRatingSubmit = useCallback(async (ratingScore) => {

        if (!user) {
            alert("Please log in to use this feature. 😘");
            localStorage.setItem('redirectAfterLogin', window.location.pathname);
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(RATINGS_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({
                    userId: user.userId,
                    entityId: titleId,
                    entityType: 'title',
                    ratingScore
                })
            });
            const data = await response.json();
            if (response.ok && data.success) {
                setUserRating(ratingScore);
                setAverageRating(data.averageRating);
                setRatingsCount(data.ratingsCount);
                return true; // Indicate successful submission
            } else {
                console.error('Error submitting rating:', data.message);
                return false; // Indicate failed submission
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
            return false; // Indicate failed submission
        }
    }, [RATINGS_API_URL, titleId, user]);

    // Handle Like toggle
    const handleLikeToggle = useCallback(async () => {
        if (!user) {
            alert("Please log in to use this feature. 😘");
            localStorage.setItem('redirectAfterLogin', window.location.pathname);
            navigate('/login');
            return;
        }
        const method = isFavorite ? 'DELETE' : 'POST';
        // console.log(method);
        const url = isFavorite ? `${LIKE_API_URL}/${titleId}/title` : LIKE_API_URL;
        // const url = isFavorite ? `${LIKE_API_URL}/${titleId}/title` : LIKE_API_URL;
        // console.log(`Token: ${user.token}`); // Debugging: Log the token

        // API call to add/remove from favorite
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}` // Ensure the token is correctly included
                },
                body: JSON.stringify({ userId: user.userId, entityId: titleId, entityType: 'title' })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setIsFavorite(!isFavorite);
        } catch (error) {
            console.error('Error handling favorite:', error);
        }
    }, [LIKE_API_URL, isFavorite, titleId, user]);

    // Handle Save toggle
    const handleSaveToggle = useCallback(async () => {
        if (!user) {
            alert("Please log in to use this feature. 😘");
            localStorage.setItem('redirectAfterLogin', window.location.pathname);
            navigate('/login');
            return;
        }

        const method = isWatchlisted ? 'DELETE' : 'POST';
        const url = isWatchlisted ? `${SAVE_API_URL}/${titleId}/title` : SAVE_API_URL;

        // console.log(`Token: ${user.token}`); // Debugging: Log the token

        // API call to add/remove from saves
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}` // Ensure the token is correctly included
                },
                body: JSON.stringify({ userId: user.userId, entityId: titleId, entityType: 'title' })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setIsWatchlisted(!isWatchlisted);
        } catch (error) {
            console.error('Error handling watchlist:', error);
        }
    }, [SAVE_API_URL, isWatchlisted, titleId, user]);

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message} </div>
    }

    const pageTitle = `${titleDetails.title_name_cn} ${titleDetails.title_name_en} (${titleDetails.title_year}) - Hanfu World`;
    const pageDescription = titleDetails.title_description_cn || "Watch Hanfu Chinese shows and movies online";

    console.log(titleDetails.title_id);

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content={`Hanfu, ${titleDetails.title_name_en}, ${titleDetails.title_name_cn}, ${titleDetails.title_type_en}, ${titleDetails.title_year}`} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={titleDetails.title_image_url} />
                <meta property="og:type" content={titleDetails.title_type_en === "cmovie" ? "video.movie" : "video.tv_show"} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={titleDetails.title_description_en || "Discover more about this title at Hanfu World."} />
                <meta name="twitter:image" content={titleDetails.title_image_url} />
                <link rel="canonical" href={`https://hanfuworld.ai/titles/${titleId}`} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": titleDetails.title_type_en === "cmovie" ? "Movie" : "TVSeries",
                        "name": titleDetails.title_name_en,
                        "alternateName": titleDetails.title_name_cn,
                        "image": titleDetails.title_image_url,
                        "datePublished": titleDetails.title_airdate,
                        "description": titleDetails.title_description_en,
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": titleDetails.title_average_rating,
                            "reviewCount": titleDetails.title_reviews_count
                        }
                    })}
                </script>
            </Helmet>
            <main className="page-wrapper">
                <article className="title-page-container">
                    <section className="title-page-left">
                        <div className="title-info">
                            <header className="title-top">
                                <div className="title-top-container">
                                    <div className="title-image-container">
                                        <img
                                            className="title-page-image"
                                            src={titleDetails.title_image_url}
                                            alt={`Poster for ${titleDetails.title_name_en}`}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="title-details">
                                        <div className="title-details-item">
                                            <span className="title-item-title">{titleDetails.title_name_cn} ({titleDetails.title_year})</span>
                                        </div>
                                        <div className="title-details-item">
                                            <span className="title-item-title">{titleDetails.title_name_en} ({titleDetails.title_year})</span>
                                        </div>
                                        <div className="title-details-item">
                                            <span className="title-item-label">评分 Rating:</span>
                                            <div>
                                                <span className="title-item-text title-ratings-item">
                                                    <span className="title-ratings-info">
                                                        <span className="title-ratings-score">
                                                            {averageRating.toFixed(1)}
                                                            {/* {titleDetails.title_average_rating || "0.00"} */}
                                                        </span>
                                                        <span className="title-ratings-count">
                                                            ({ratingsCount})
                                                            {/* ({titleDetails.title_ratings_count || 0}) */}
                                                        </span>
                                                    </span>

                                                    <span className="title-ratings">
                                                        <StarRating
                                                            titleId={titleId}
                                                            user={user}
                                                            initialRating={userRating}
                                                            averageRating={averageRating}
                                                            onRatingSubmit={handleRatingSubmit}
                                                            navigate={navigate}
                                                        />
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="title-details-item">
                                            <small><i>(Review coming soon)</i></small>
                                            {/* <span className="title-rating-note">{titleDetails.title_reviews_count === 0 ?
                                            <span>(Be the first reviewer! ✍🏻)</span>
                                            :
                                            <span>""</span>
                                        }</span> */}
                                        </div>
                                        {/* <div className="title-details-item">
                                    <span className="title-item-label">导演 Director:</span>{" "}
                                    <span className="title-item-text"></span>
                                </div> */}
                                        {/* <div className="title-details-item">
                                    <span className="title-item-label">主演 Main Cast:</span>{" "}
                                    <span className="title-item-text"></span>
                                </div> */}
                                        <div className="title-details-item">
                                            <span className="title-item-label">首播 Air Date:</span>{" "}
                                            <span className="title-item-text">{titleDetails.title_airdate?.split("T")[0]}</span>
                                        </div>
                                        {titleDetails.title_type_en === "cmovie" ? null :
                                            (
                                                <div className="title-details-item">
                                                    <span className="title-item-label">集数 Episodes:</span>{" "}
                                                    <span className="title-item-text">{titleDetails.title_episodes}</span>
                                                </div>
                                            )
                                        }
                                        <div className="title-details-item">
                                            {
                                                titleDetails.title_type_en === "cmovie" ?
                                                    <span className="title-item-label">时长 Duration:</span>
                                                    :
                                                    <span className="title-item-label">片长 Duration:</span>
                                            }
                                            <span className="title-item-text">{titleDetails.title_duration}</span>
                                        </div>

                                        <div className="title-details-item">
                                            <span className="title-item-label">简介 Description:</span>{" "}
                                            {/* <span className="title-item-text"> {titleDetails.title_description_cn}</span> */}
                                        </div>
                                        <div className="title-details-item">
                                            <span className="title-item-label">可看 Avaiable at:</span>{" "}
                                            <span className="title-item-channels"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="title-top-right">
                                </div>
                                <section className="title-mid-container">
                                    <div className="tabbed-container">
                                        <div className="title-actions">
                                            <button
                                                className={`btn btn-light btn-title-action tab-button ${activeTab === 'trailer' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('trailer')}
                                                aria-label="View trailer"
                                            >
                                                <span>预告 <PiTelevisionSimpleBold /></span>
                                                <span>Trailer</span>
                                            </button>
                                            <button
                                                className={`btn btn-light btn-title-action tab-button ${activeTab === 'watch' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('watch')}
                                                aria-label="Watch now"
                                            >
                                                <span>播放 <PiPlayBold /></span>
                                                <span>Watch</span>
                                            </button>
                                            <button
                                                className={`btn btn-light btn-title-action tab-button ${activeTab === 'shop' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('shop')}
                                                aria-label="Watch now"
                                            >
                                                <span>购买 <RiShoppingBagLine /></span>
                                                <span>Shop</span>
                                            </button>
                                            <button
                                                className="btn btn-light btn-title-action"
                                                onClick={handleLikeToggle}
                                                aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
                                            >
                                                <span>
                                                    喜欢
                                                    {isFavorite ? <FaHeart className="title-action-icon" /> : <FaRegHeart className="title-action-icon" />}
                                                </span>
                                                <span>
                                                    Like
                                                </span>
                                            </button>
                                            <button
                                                className="btn btn-light btn-title-action"
                                                onClick={handleSaveToggle}
                                                aria-label={isWatchlisted ? "Remove from watchlist" : "Add to watchlist"}
                                            >
                                                <span>
                                                    收藏
                                                    {isWatchlisted ? <FaBookmark className="title-action-icon" /> : <FaRegBookmark className="title-action-icon" />}
                                                </span>
                                                <span>
                                                    Save
                                                </span>
                                            </button>
                                        </div>
                                        <div className="tab-content">
                                            {activeTab === 'trailer' && (
                                                <div className="title-trailer-container">
                                                    {titleDetails.title_video_url ? (
                                                        <div className="title-video-responsive">
                                                            <iframe
                                                                className="title-embed-video"
                                                                width="800"
                                                                height="450"
                                                                src={`${titleDetails.title_video_url}`}
                                                                title={`${titleDetails.title_name_en} Trailer`}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen
                                                                loading="lazy"
                                                            />
                                                            {/* <ReactPlayer url={`${titleDetails.title_video_url}`} /> */}
                                                        </div>
                                                    ) : (
                                                        <div className="container-text">Coming soon...</div>
                                                    )}
                                                </div>
                                            )}
                                            {activeTab === 'watch' && (
                                                <Watchlinks titleId={titleDetails.title_id} />
                                            )}
                                          
                                            {activeTab === 'shop' && (
                                                <TitleProducts
                                                    entityId={titleDetails.title_id}
                                                    entityType="title"
                                                />
                                            )}
                                        </div>
                                    </div>

                                </section>
                            </header>

                            {/* <div className="title-bottom-container">
                        <TitleReviewForm titleId={titleDetails.title_id} />
                        <TitleReviews titleId={titleId} />
                        <TitleComments titleId={titleId} />
                    </div> */}
                        </div>
                    </section>
                    {/* <div className="title-page-right">
                </div> */}
                </article>
            </main>
        </>
    )
}

export default TitlePage;





