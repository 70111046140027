import React from 'react';
import { MdOutlineAccessTime } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import '../../css/Event.css';

const parseDateTime = (dateString, timeString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
};

const EventCard = ({ event }) => {
    const now = new Date();
    const eventStartDate = parseDateTime(event.event_start_date, event.event_start_time);
    const eventEndDate = parseDateTime(event.event_end_date, event.event_end_time);

    const getEventStatus = () => {
        if (now < eventStartDate) {
            return { status: 'upcoming', label: 'UPCOMING' };
        } else if (now > eventEndDate) {
            return { status: 'past', label: 'PAST' };
        } else {
            return { status: 'ongoing', label: 'ONGOING' };
        }
    };

    const { status, label } = getEventStatus();

    const formatDateTime = (date) => {
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    return (
        <div className="event-card">
            <div className="event-image-container">
                <img className="event-image" src={event.event_image_url} alt={event.event_title_en} />
            </div>
            <div className="event-details">
                <div className="event-title-container">
                    <div className="event-title">
                        <span className="event-name-cn">{event.event_title_cn}</span>
                        <span className="event-name-en">{event.event_title_en}</span>
                    </div>
                    <span className={`event-badge event-${status}-badge`} aria-label={`Event status: ${label}`}>
                        {label}
                    </span>
                </div>

                <div className="event-description">{event.event_description}</div>
                <div className="event-time">
                    <MdOutlineAccessTime className="event-icon" />
                    <span>{`${formatDateTime(eventStartDate)}`}</span>
                </div>
                <div className="event-location">
                    <GrLocation className="event-icon" />
                    <span>{event.event_city_en ? `${event.event_city_en}, ${event.event_state_en}` : "TBA"}</span>
                </div>
                <div className="event-tags">
                    {event.is_free && <span className="event-tag">Free</span>}
                    {event.event_type && <span className="event-tag">{event.event_type}</span>}
                </div>
            </div>
        </div>
    );
}

export default EventCard;