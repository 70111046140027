import React from 'react';
import { Link } from 'react-router-dom'; 
import "../../css/Page.css";

const Terms = () => {

    return (
        <div className="page-wrapper">
            <div className="terms-container">
                <h5 className="page-subheader">
                    Hanfu World Terms & Conditions of Service
                </h5>
                <div className="terms-content-container">
                    <h6 className="terms-header">
                        Introduction
                    </h6>
                    <p className="terms-text">
                        Welcome to Hanfu World! By accessing our website or using our services, you agree to be bound by the following terms and conditions. If you do not agree with any part of these terms, please do not use our services.
                    </p>
                    <h6 className="terms-header">
                        Use of Services
                    </h6>
                    <p className="terms-text">
                        Hanfu World provides a platform for users to learn about, discuss, and engage with content related to Hanfu culture. Our services are available to users who agree to use them in a respectful, lawful manner without infringing on the rights of others or the operational integrity of our platform.
                    </p>
                    <h6 className="terms-header">User Conduct</h6>
                    <p className="terms-text">
                        You are responsible for all activities under your account. Prohibited activities include:

                        Posting or transmitting content that is unlawful, harmful, or violates the rights of others
                        Attempting to disrupt the services or accessing them in an unauthorized manner
                        Infringing on the intellectual property rights of others
                        Content Ownership

                        Content provided by Hanfu World is owned by us or our content providers. You may not use this content except as permitted by these terms or with prior written consent from us.
                    </p>
                    <h6 className="terms-header">
                        Disclaimers
                    </h6>
                    <p className="terms-text">
                        Our services are provided "as is" without warranties of any kind. We do not guarantee the accuracy, completeness, or timeliness of the information available through our services.
                    </p>

                    <h6 className="terms-header">
                        Limitation of Liability
                    </h6>
                    <p className="terms-text">
                        Hanfu World will not be liable for any damages arising out of your use or inability to use our services.
                    </p>
                    <h6 className="terms-header">
                        Changes to Terms
                    </h6>
                    <p className="terms-text">
                        We reserve the right to modify these terms at any time. Your continued use of the services after any changes signifies your acceptance of the new terms.
                    </p>
                    <h6 className="terms-header">
                        Contact Us
                    </h6>
                    <p className="terms-text">
                        If you have any questions about these terms, please contact us <Link to="/contact">here</Link>.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Terms; 