// src/components/create/ImageForm.js 

import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import { Spinner } from 'react-bootstrap';
import { FaDownload, FaShare } from 'react-icons/fa6';
import SocialShare from './SocialShare';
import PartyButton from './PartyButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/Generate.css';

function ImageForm({ userId, userToken }) {

    // Setting API base URL 
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
    const USE_TOKEN_URL = `${API_BASE_URL}/api/users/${userId}/tokens/use`;
    const GENERATE_IMAGE_URL = `${API_BASE_URL}/api/generate-image`;
    // const AI_API_BASE_URL = process.env.REACT_APP_AI_API_BASE_URL || 'http://localhost:8080';
    // const GENERATE_IMAGE_URL = `${AI_API_BASE_URL}/generate-image`;

    const [prompt, setPrompt] = useState('');
    const [image, setImage] = useState(null);
    const [generatedImages, setGeneratedImages] = useState([]);
    const [remainingToken, setRemainingToken] = useState(userToken);
    const [showShareOptions, setShowShareOptions] = useState(false);
    // Ref for the share container to handle outside clicks
    const shareRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // Update whenever userToken changes
        setRemainingToken(userToken);
    }, [userToken]);

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        marginTop: '10px'
    };

    const handlePromptChange = (e) => {
        // console.log("You entered a prompt!")
        // setPrompt(e.target.value);
        const value = e.target.value;
        setPrompt(value);
    };

    const handleImageChange = (e) => {
        // console.log("You uploaded a image!")
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        // console.log("You submitted a generate image request!")

        e.preventDefault();
        if (remainingToken <= 0) {
            alert('No tokens left to generate an image.')
            return;
        }

        const formData = new FormData();

        // Include user ID here
        formData.append('user_id', userId);

        // Frontend validation (React)
        const maxPromptLength = 450; // characters
        const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

        if (prompt.length > maxPromptLength) {
            alert("Uh-oh, the Prompt is too long. Try to keep it under 450 characters. 😛");
            return;
        };

        formData.append('prompt', prompt);

        console.log(prompt)

        if (image && !['image/jpeg', 'image/png'].includes(image.type)) {
            alert("Please uplodate JPEG and PNG images only.");
            return;
        };
        if (image && image.size > maxFileSize) {
            alert("Oops! The file size is too large. Try to keep it under 5MB. 😅");
            return;
        };

        if (image) {
            formData.append('image', image);
        }

        // Start loading
        setIsLoading(true);

        try {

            const response = await fetch(GENERATE_IMAGE_URL, {
                method: 'POST',
                body: formData,
            });


            if (!response.ok) {
                const errorData = await response.json();
                // throw new Error(imageData.message || 'Failed to generate image.');
                throw new Error(errorData.error || 'Failed to generate image.');
            }

            const imageData = await response.json();
            // console.log("Received image data:", imageData);

            // setGeneratedImages(imageData.images.map(image => `/user_files/${image}`));
            // Ensure that generated_images is always an array
            // const images = imageData.generated_images || [];
            // setGeneratedImages(images.map(image => `/user_files/${image}`));
            setGeneratedImages(imageData.generated_images);

            // Use token 
            const tokenResponse = await fetch(USE_TOKEN_URL, {
                method: 'POST',
            });

            const tokenData = await tokenResponse.json();

            if (!tokenResponse.ok) throw new Error(tokenData.message || 'Failed to use token.');
            setRemainingToken(prev => prev - 1);

        } catch (error) {
            console.error('Error generating image:', error);
            // Reset or handle the state appropriately
            setGeneratedImages([]);
        } finally {
            // Stop loading regardless of success or error
            setIsLoading(false);
            // Reset the form fields
            setPrompt(''); // Clear prompt input field
            setImage(null); // Clear image file input
        }
    };

    // Manage outside clicks 
    useEffect(() => {
        function handleClickOutside(event) {
            if (shareRef.current && !shareRef.current.contains(event.target)) {
                setShowShareOptions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [shareRef]);

    const toggleShareOptions = () => {
        setShowShareOptions(!showShareOptions);
    };

    const handlePartyClick = (event) => {
        event.preventDefault();
        // Set a timeout to navigate after 1 second (1000 milliseconds)
        setTimeout(() => {
            // Use window.location for navigation
            window.open("/buy", "_blank");
        }, 1000);
    };

    return (
        <div className="imageform-container">
            {isLoading && (
                <div className="loading-section">
                    <div className="loading-texts">
                        <span className="loading-cn">生成中，请稍等。。。😍（请不要关闭网页！）</span>
                        <span className="loading-en">Generating, please wait...😍 (Do not close this page!) </span>
                    </div>
                    <Spinner className="spinner" animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            <div>
                {generatedImages.map((imgUrl, index) => (
                    <div key={index}>
                        <img className="generated-image" src={imgUrl} alt="Generated" style={imageStyle} />
                        <div className="generated-image-actions">
                            <div className="image-action-buttons">
                                <a className="download-link" href={imgUrl} download={`MyGeneratedHanfuImage_${index}.png`}>
                                    <button className="btn btn-light btn-download">下载 Download <FaDownload className="btn-download-icon" /></button>
                                </a>
                                <div className="share-link">
                                    <button className="btn btn-light btn-download" onClick={toggleShareOptions}>分享 Share <FaShare className="btn-download-icon" /></button>
                                </div>
                                {showShareOptions && <SocialShare imgUrl={imgUrl} />}

                            </div>
                        </div>

                        {/* <div className="loading-texts">
                            <span className="loading-cn">你还有 <span className="token-count">{remainingToken}</span>  个代币，在这里拿更多 😘 <a href="/buy" target="_blank"><button className="btn btn-light btn-buy-token">买代币！</button></a></span>
                            <span className="loading-en">You have <span className="token-count">{remainingToken}</span> token(s) left, get more tokens here 😘 <a href="/buy" target="_blank"><button className="btn btn-light btn-buy-token">Buy Tokens!</button></a></span>
                        </div> */}

                        <div className="loading-texts">
                            <span className="loading-cn">你还有 <span className="token-count">{remainingToken}</span>  个代币，在这里拿更多 😘
                                <a href="/buy" target="_blank" onClick={handlePartyClick}>
                                    <PartyButton>
                                        买代币！
                                    </PartyButton>
                                </a>
                            </span>
                            <span className="loading-en">You have <span className="token-count">{remainingToken}</span> token(s) left, get more tokens here 😘
                                <a href="/buy" target="_blank" onClick={handlePartyClick}>
                                    <PartyButton>
                                        Buy Tokens!
                                    </PartyButton>
                                </a>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <form className="image-form" onSubmit={handleSubmit}>
                {/* <div className="form-group">
                    <label htmlFor="style-select" className="form-label label-text">选择风格 Choose style:  <span className="form-text">(自选 optional)</span></label>
                    <select id="style-select" name="style-type">
                        <option disabled value="">选择风格 Choose style: </option>
                        <option value="manhua">漫画 Manhua</option>
                        <option value="donghua">动画 Donghua</option>
                        <option value="cdrama">连续剧 C-Drama</option>
                        <option value="cmovie">电影 C-Movie</option>
                    </select>
                </div> */}
                <div className="form-group">
                    <label htmlFor="prompt" className="form-label label-text">指令 Prompt: <span className="form-text">*(必须 required)</span></label>
                    <div className="text-box">
                        <span className="form-text">指令字数限制 Prompt character limit: 450</span>
                    </div>
                    <div className="textarea-container">
                        <textarea className="form-control prompt-input"
                            id="prompt"
                            name="prompt"
                            value={prompt}
                            onChange={handlePromptChange}
                            placeholder="用短句和词语描述您要生成的图像，比如：一个唐朝的女人。Describe the image you want to generate in phrases & key words. Ex: A woman in Tang Dynasty"
                            rows="5"
                            required
                        />
                    </div>
                </div>
                {/* <div className="text-box">
                    <span className="form-text">
                        例如：“一个美丽的女孩微笑着，穿着唐制汉服，看起来就像电视剧《琉璃》中的女主角。”
                    </span>
                    <span className="form-text">
                        Example: "A beautiful girl smiling, in Tang Dynasty Hanfu, looking like the female lead in the Chinese drama Love and Redemption. "
                    </span>
                </div> */}

                <div className="form-group">
                    <label htmlFor="image" className="form-label label-text">上传照片 Upload Image: <span className="form-text">(自选 optional)</span></label>
                    <input type="file" className="form-control" id="image" name="image" onChange={handleImageChange} key={image ? 'not-reset' : 'reset'} />
                </div>

                <button className="btn btn-light btn-generate" type="submit" disabled={isLoading}>
                    {isLoading ?
                        '生成中 Generating...❤️' : '生成图像 Generate Image'
                    }
                </button>

                {/* <button className="btn btn-light btn-generate" type="submit">生成图像 Generate Image</button> */}
                <span className="form-text">* 最大文件 Maximum file size: 1024x1024</span>
            </form>

            {/* <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: "75%"}}></div>
            </div> */}
        </div>
    );
};

export default ImageForm;
