// src/components/create/GenerateImage.js 

import React, { useState, useEffect, useContext, memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import UserContext from '../../context/UserContext';
// import ImageForm from '../components/ImageForm'; 
import ImageForm from './ImageForm';
import PartyButton from './PartyButton';
import "../../css/Generate.css";

const GenerateImage = () => {
    // Getting userId from URL if available
    const { userId } = useParams();
    // console.log("userId:", userId);
    console.log("UserId:", userId);
    const { user: currentUser } = useContext(UserContext);
    const [userToken, setUserToken] = useState(0);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // console.log("Current user from context:", currentUser);
    // console.log("userId from useParams:", userId);
    const USER_TOKENS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/tokens` || `http://localhost:3000/api/users/${userId}/tokens`;
    console.log(USER_TOKENS_API_URL);

    useEffect(() => {
        const fetchUserTokens = async () => {
            if (!currentUser || !userId) return;  // Ensure there's a current user
            setIsLoading(true);
            try {
                const response = await fetch(USER_TOKENS_API_URL);
                const data = await response.json();
                if (response.ok) {
                    setUserToken(data.tokens_remaining);
                } else {
                    throw new Error(data.message || 'Failed to fetch tokens');
                }
            } catch (err) {
                setError(err.message || 'An error occured while fetching user tokens.');
            } finally {
                setIsLoading(false);
            }
        }; 
        
        fetchUserTokens();
    }, [userId, currentUser,USER_TOKENS_API_URL]);

    console.log("userToken:", userToken);

    if (isLoading) {
        return <div>Loading...</div>
    }


    const handlePartyClick = (event) => {
        event.preventDefault();
        // Set a timeout to navigate after 1 second (1000 milliseconds)
        setTimeout(() => {
            window.open("/buy", "_blank")
        }, 1000);
    };

    return (
        <div className="page-wrapper">
            <div className="generate-container">
                <h2 className="create-header">生成你的AI汉服头像! :)</h2>
                <h3 className="create-subheader">Generate Your AI Hanfu Portrait! :)</h3>
                {currentUser && userToken > 0 ?
                    <ImageForm userId={userId} userToken={userToken} />
                    :
                    <div className="get-token-container">
                        {/* <button className="btn btn-light btn-get-token ">
                            <h5 className="token-header">哎呀! 你没有代币了💔。来这里拿更多 😍🥳🎉！</h5>
                            <h5 className="token-header">Oh no! You don't have anymore tokens💔. Get more here 😍🥳🎉! </h5>
                        </button> */}
                        <a href="/buy" onClick={handlePartyClick} target="_blank">
                            <PartyButton>
                                <h5 className="token-header">哎呀! 你没有代币了💔。来这里拿更多 😍🥳🎉！</h5>
                                <h5 className="token-header">Oh no! You don't have anymore tokens💔. Get more here 😍🥳🎉! </h5>
                            </PartyButton>
                        </a>

                        <img className="create-image" src="/images/landing/create_1.png" alt="landing-create" />
                    </div>
                }
            </div>
        </div>
    )
};

export default GenerateImage; 